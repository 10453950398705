import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './common/auth.guard';

const routes: Routes = [
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule',
  canLoad: [AuthGuard]},

  { path: 'about', loadChildren: './pages/about/about.module#AboutPageModule',
  canLoad: [AuthGuard] },

  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule',
  canLoad: [AuthGuard] },

  { path: 'edit-profile', loadChildren: './pages/edit-profile/edit-profile.module#EditProfilePageModule',
  canLoad: [AuthGuard] },

  { path: 'home-results', loadChildren: './pages/home-results/home-results.module#HomeResultsPageModule',
  canLoad: [AuthGuard] },

   { path: 'new-pass', loadChildren: './pages/passes/new-pass/new-pass.module#NewPassPageModule',
   canLoad: [AuthGuard] },

  { path: 'view-pass/:id', loadChildren: './pages/passes/view-pass/view-pass.module#ViewPassPageModule',
  canLoad: [AuthGuard] },

  { path: 'all-passes', loadChildren: './pages/passes/all-passes/all-passes.module#AllPassesPageModule',
  canLoad: [AuthGuard] },

  { path: 'edit-pass/:id', loadChildren: './pages/passes/edit-pass/edit-pass.module#EditPassPageModule',
  canLoad: [AuthGuard] },

  { path: 'edit-visitor/:id', loadChildren: './pages/visitors/edit-visitor/edit-visitor.module#EditVisitorPageModule',
  canLoad: [AuthGuard] },

  { path: 'view-visitor/:id', loadChildren: './pages/visitors/view-visitor/view-visitor.module#ViewVisitorPageModule',
  canLoad: [AuthGuard] },

  { path: 'new-dependent', loadChildren: './pages/dependents/new-dependent/new-dependent.module#NewDependentPageModule',
  canLoad: [AuthGuard] },

  { path: 'edit-dependent/:id', loadChildren: './pages/dependents/edit-dependent/edit-dependent.module#EditDependentPageModule',
  canLoad: [AuthGuard] },

  { path: 'view-dependent/:id', loadChildren: './pages/dependents/view-dependent/view-dependent.module#ViewDependentPageModule',
  canLoad: [AuthGuard] },

  { path: 'all-dependents', loadChildren: './pages/dependents/all-dependents/all-dependents.module#AllDependentsPageModule',
  canLoad: [AuthGuard] },
  { path: 'my-passes', loadChildren: './pages/passes/my-passes/my-passes.module#MyPassesPageModule' },
  { path: 'arrival-notice', loadChildren: './pages/arrival-notice/arrival-notice.module#ArrivalNoticePageModule' },
  { path: 'new-alert', loadChildren: './pages/modal/new-alert/new-alert.module#NewAlertPageModule' },
  { path: 'gate-passes', loadChildren: './pages/passes/gate-passes/gate-passes.module#GatePassesPageModule',  canLoad: [AuthGuard] },
  { path: 'edit-gatepass/:id', loadChildren: './pages/passes/edit-gatepass/edit-gatepass.module#EditGatepassPageModule',
    canLoad: [AuthGuard]  },
  { path: 'edit-pending-gatepass/:id', loadChildren: './pages/passes/edit-pending-gatepass/edit-pending-gatepass.module#EditPendingGatepassPageModule',
   canLoad: [AuthGuard]  },
  { path: 'notifications', loadChildren: './pages/notifications/notifications.module#NotificationsPageModule',  canLoad: [AuthGuard] },
  { path: 'view-gatepass/:id', loadChildren: './pages/passes/view-gatepass/view-gatepass.module#ViewGatepassPageModule' ,  canLoad: [AuthGuard] },
  { path: 'edit-pending-booking/:id', loadChildren: './pages/passes/edit-pending-booking/edit-pending-booking.module#EditPendingBookingPageModule',  canLoad: [AuthGuard]},
  { path: 'view-pending-booking/:id', loadChildren: './pages/passes/view-pending-booking/view-pending-booking.module#ViewPendingBookingPageModule',  canLoad: [AuthGuard]},
  { path: 'view-pending-gatepass/:id', loadChildren: './pages/passes/view-pending-gatepass/view-pending-gatepass.module#ViewPendingGatepassPageModule',  canLoad: [AuthGuard]},
  { path: 'residents', loadChildren: './pages/resident/residents/residents.module#ResidentsPageModule',  canLoad: [AuthGuard]  },
  { path: 'view-resident/:id', loadChildren: './pages/resident/view-residents/view-residents.module#ViewResidentsPageModule',  canLoad: [AuthGuard]  },
  { path: 'edit-resident/:id', loadChildren: './pages/resident/edit-resident/edit-resident.module#EditResidentPageModule',  canLoad: [AuthGuard]  },
  { path: 'transactions', loadChildren: './pages/transaction/transactions/transactions.module#TransactionsPageModule',  canLoad: [AuthGuard] },
  { path: 'view-transaction/:id', loadChildren: './pages/transaction/view-transaction/view-transaction.module#ViewTransactionPageModule',  canLoad: [AuthGuard] },
  { path: 'services/:category', loadChildren: './pages/transaction/services/services.module#ServicesPageModule',  canLoad: [AuthGuard] },
  { path: 'service/:id', loadChildren: './pages/transaction/service/service.module#ServicePageModule',  canLoad: [AuthGuard] },
  { path: 'service-categories', loadChildren: './pages/transaction/service-categories/service-categories.module#ServiceCategoriesPageModule',  canLoad: [AuthGuard] },
  { path: 'admin-add-dependent/:id', loadChildren: './pages/dependents/admin-add-dependent/admin-add-dependent.module#AdminAddDependentPageModule',  canLoad: [AuthGuard] },
  { path: 'resident-dependents/:id', loadChildren: './pages/dependents/resident-dependents/resident-dependents.module#ResidentDependentsPageModule',  canLoad: [AuthGuard] },
  { path: 'visitors', loadChildren: './pages/visitors/visitors/visitors.module#VisitorsPageModule',  canLoad: [AuthGuard] }









];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
