var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
var NewAlertPage = /** @class */ (function () {
    function NewAlertPage(authService, modalCtrl, loadingCtrl, alertCtrl, commonService, navCtrl, datePipe) {
        this.authService = authService;
        this.modalCtrl = modalCtrl;
        this.loadingCtrl = loadingCtrl;
        this.alertCtrl = alertCtrl;
        this.commonService = commonService;
        this.navCtrl = navCtrl;
        this.datePipe = datePipe;
        this.today = new Date(Date.now());
        this.getMaxDate = new Date(this.today.getUTCFullYear() + 3, 11, 31);
        this.currDate = this.datePipe.transform(this.today, 'yyyy-MM-dd').toString();
        this.maxDate = this.datePipe.transform(this.getMaxDate, 'yyyy-MM-dd').toString();
        this.currTime = this.datePipe.transform(this.today, 'HH:mm').toString();
    }
    NewAlertPage.prototype.ngOnInit = function () {
        if (!window.history.state.modal) {
            var modalState = { modal: true };
            history.pushState(modalState, null);
        }
        this.myForm = new FormGroup({
            firstName: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.required]
            }),
            lastName: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.required]
            }),
            email: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.email]
            }),
            phone: new FormControl('+234', {
                updateOn: 'change',
                validators: [Validators.required, Validators.pattern('^[+]{1}[0-9]{3}[0-9]{5,15}$')]
            }),
            fromDate: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.required]
            }),
            toDate: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.required]
            }),
            notification: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.required]
            }),
            note: new FormControl(null, {
                updateOn: 'change'
            }),
            purpose: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.required]
            }),
            arrivalTime: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.required]
            }),
            item: new FormControl(null, {
                updateOn: 'change',
                validators: [Validators.required]
            }),
            plateNumber: new FormControl(null, {
                updateOn: 'change'
            })
        });
    };
    Object.defineProperty(NewAlertPage.prototype, "firstName", {
        get: function () {
            return this.myForm.get('firstName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "lastName", {
        get: function () {
            return this.myForm.get('lastName');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "email", {
        get: function () {
            return this.myForm.get('email');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "phone", {
        get: function () {
            return this.myForm.get('phone');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "arrivalTime", {
        get: function () {
            return this.myForm.get('arrivalTime');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "fromDate", {
        get: function () {
            return this.myForm.get('fromDate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "toDate", {
        get: function () {
            return this.myForm.get('toDate');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "notification", {
        get: function () {
            return this.myForm.get('notification');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "note", {
        get: function () {
            return this.myForm.get('note');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "plateNumber", {
        get: function () {
            return this.myForm.get('plateNumber');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "purpose", {
        get: function () {
            return this.myForm.get('purpose');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAlertPage.prototype, "item", {
        get: function () {
            return this.myForm.get('item');
        },
        enumerable: true,
        configurable: true
    });
    NewAlertPage.prototype.createGatepass = function () {
        var _this = this;
        if (this.myForm.invalid) {
            this.showAlert('Please fill out all form fields correctly!');
        }
        else {
            var itemList = this.myForm.value.item.split(',');
            var item_1 = [], count_1 = 1;
            if (itemList.length > 0) {
                itemList.forEach(function (val) {
                    item_1.push({
                        item: val,
                        sn: count_1
                    });
                    count_1++;
                });
            }
            var formattedFromDate = this.datePipe.transform(this.myForm.value.fromDate, 'yyyy-MM-dd');
            var formattedToDate = this.datePipe.transform(this.myForm.value.toDate, 'yyyy-MM-dd');
            var formattedFromTime = this.datePipe.transform(this.myForm.value.arrivalTime, 'HH:mm');
            var userEmail_1;
            this.authService.userInfo.subscribe(function (user) {
                userEmail_1 = user.email;
            });
            var data_1 = {
                firstName: this.myForm.value.firstName,
                lastName: this.myForm.value.lastName,
                email: this.myForm.value.email || userEmail_1,
                phone: this.myForm.value.phone,
                purpose: this.myForm.value.purpose,
                fromDate: formattedFromDate,
                toDate: formattedToDate,
                arrivalTime: formattedFromTime,
                items: item_1,
                plateNumber: this.myForm.value.plateNumber,
                notification: this.myForm.value.notification,
                note: this.myForm.value.note,
            };
            this.isLoading = true;
            this.loadingCtrl
                .create({ keyboardClose: true, message: 'Please Wait...' })
                .then(function (loadingEl) {
                loadingEl.present();
                _this.commonService.createGatepass(data_1).subscribe(function (success) {
                    if (success.gatePass || success.pendingGatePass) {
                        _this.isLoading = false;
                        loadingEl.dismiss();
                        _this.sendWhatsapp(success);
                        _this.modalCtrl.dismiss();
                    }
                    else {
                        _this.isLoading = false;
                        loadingEl.dismiss();
                        _this.showAlert('An error occurred. Please check your data and try again');
                    }
                }, function (error) {
                    _this.showAlert(error);
                });
            });
        }
    };
    NewAlertPage.prototype.closeModal = function () {
        this.modalCtrl.dismiss();
    };
    NewAlertPage.prototype.showAlert = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: 'Heads Up!',
                            message: message,
                            buttons: ['Okay']
                        }).then(function (alertEl) {
                            alertEl.present();
                        })];
                    case 1:
                        alert = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NewAlertPage.prototype.sendWhatsapp = function (bookingData) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: 'Success!',
                            message: 'Pass Created Successfully. Click Send to WhatsApp to attempt to send this booking info to the recipient on WhatsApp',
                            buttons: [
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: function () {
                                        _this.modalCtrl.dismiss();
                                    }
                                }, {
                                    text: 'Send to WhatsApp',
                                    handler: function (data) { return __awaiter(_this, void 0, void 0, function () {
                                        var whatsappMessage, phoneNum, whatsappUrl;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    whatsappMessage = encodeURI('Hi ' + bookingData.gatePass.firstName + ' ' + bookingData.gatePass.lastName +
                                                        ', I have just booked you into ' + bookingData.estate + '. Your Booking code is ' + bookingData.gatePass.bookingCode +
                                                        '. Please Come along with the QR image in the link below or your booking Code. Thank You.\n ' + bookingData.q);
                                                    phoneNum = bookingData.gatePass.phone;
                                                    whatsappUrl = 'https://api.whatsapp.com/send?phone=' + phoneNum + '&text=' + whatsappMessage;
                                                    return [4 /*yield*/, window.open(whatsappUrl)];
                                                case 1:
                                                    _a.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return NewAlertPage;
}());
export { NewAlertPage };
