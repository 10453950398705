<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="closeModal()">
        <ion-icon name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      New Gatepass
    </ion-title>
  </ion-toolbar>
</ion-header>


<ion-content padding>
  <ion-list-header>
    RECIPIENT DETAILS
  </ion-list-header>
  <form [formGroup]="myForm" *ngIf="myForm" (submit)='createGatepass()'>
    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">First Name <span style="color: red;">*</span></ion-label>
            <ion-input placeholder="First Name" type="text" formControlName="firstName"></ion-input>
          </ion-item>
          <ion-item *ngIf="firstName.invalid && firstName.touched" lines="none">
            <ion-label class="error-label">
              First Name field is required
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">Last Name<span style="color: red;">*</span></ion-label>
            <ion-input placeholder="Last Name" type="text" formControlName="lastName"></ion-input>
          </ion-item>
          <ion-item *ngIf="lastName.invalid && lastName.touched" lines="none">
            <ion-label class="error-label">
              Last Name field is required
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">Email</ion-label>
            <ion-input placeholder="Email" type="email" formControlName="email"></ion-input>
          </ion-item>
          <ion-item *ngIf="email.invalid && email.touched" lines="none">
            <ion-label class="error-label">
              Please ensure the email is valid
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">Phone<span style="color: red;">*</span></ion-label>
            <ion-input placeholder="+2348xxxxxxxxx" type="phone" formControlName="phone"></ion-input>
          </ion-item>
          <ion-item *ngIf="phone.invalid && phone.touched" lines="none">
            <ion-label class="error-label">
              Phone number must start with international code
            </ion-label>
          </ion-item>
        </ion-col>


        <ion-list-header>
          PASS DETAILS
        </ion-list-header>
        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">From Date <span style="color: red;">*</span></ion-label>
            <ion-datetime displayFormat="MMM DD YYYY" [min]="currDate" [max]="maxDate" formControlName="fromDate">
            </ion-datetime>
          </ion-item>
          <ion-item *ngIf="fromDate.invalid && fromDate.touched" lines="none">
            <ion-label class="error-label">
              From date field is required
            </ion-label>
          </ion-item>
          <ion-item *ngIf="toDate.value != null && fromDate.value > toDate.value" lines="none">
            <ion-label class="error-label">
              From date cannot be <br> ahead of to date
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">Arrival Time <span style="color: red;">*</span></ion-label>
            <ion-datetime displayFormat="HH:mm" formControlName="arrivalTime"></ion-datetime>
          </ion-item>
          <ion-item *ngIf="arrivalTime.invalid && arrivalTime.touched" lines="none">
            <ion-label class="error-label">
              Arrival time field is required
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">End Date <span style="color: red;">*</span></ion-label>
            <ion-datetime displayFormat="MMM DD YYYY" [min]="currDate" formControlName="toDate" [max]="maxDate">
            </ion-datetime>
          </ion-item>
          <ion-item *ngIf="toDate.invalid && toDate.touched" lines="none">
            <ion-label class="error-label">
              End date field is required
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6">

          <ion-item>
            <ion-label position="floating" color="dark">Get Notified? <span style="color: red;">*</span></ion-label>
            <ion-select formControlName="notification">
              <ion-select-option [value]="true">Yes</ion-select-option>
              <ion-select-option [value]="false">No</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item *ngIf="!notification.valid && notification.touched" lines="none">
            <ion-label class="error-label">
              Select an option
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="12">
          <ion-item>
            <ion-label position="floating">Purpose <span style="color: red;">*</span></ion-label>
            <ion-select formControlName="purpose">
              <ion-select-option value="Items-in">Items In</ion-select-option>
              <ion-select-option value="Items-out">Items Out</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item *ngIf="!purpose.valid && purpose.touched" lines="none">
            <ion-label class="error-label">
              Field is required
            </ion-label>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="floating">Item(s) to Collect</ion-label>
            <ion-textarea placeholder="Seperate each item with a comma" formControlName="item"></ion-textarea>
          </ion-item>
          <ion-item *ngIf="!item.valid && item.touched" lines="none">
            <ion-label class="error-label">
              Field is required
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="12">
          <ion-item>
            <ion-label position="floating">Plate Number (Optional)</ion-label>
            <ion-input placeholder="Plate Number" formControlName="plateNumber"></ion-input>
          </ion-item>
        </ion-col>

        <ion-col size="12">
          <ion-item>
            <ion-label position="floating">Note (Optional)</ion-label>
            <ion-textarea placeholder="Note" formControlName="note"></ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-button expand="full" color="primary" type="submit"
      [disabled]="myForm.invalid || !myForm.dirty || fromDate.value > toDate.value">Submit</ion-button>
  </form>

</ion-content>