// import * as firebase from 'firebase/app';
// import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
var FirebaseService = /** @class */ (function () {
    function FirebaseService(db) {
        this.db = db;
        this.notifications = this.db.list('notifications').valueChanges();
        // this.visitorCheckins = this.visitorCheckinsCollection.snapshotChanges().pipe(
        //   map(actions => {
        //     return actions.map(a => {
        //       const data = a.payload.doc.data();
        //       const id = a.payload.doc.id;
        //       return { id, ...data };
        //     });
        //   })
        // );
    }
    /**
     * create
     */
    FirebaseService.prototype.getNotificationCount = function (account_id) {
        return this.db.list('/notifications', function (ref) { return ref.orderByChild('receiver').equalTo(account_id); }).snapshotChanges();
    };
    FirebaseService.prototype.getNotificationContent = function (account_id) {
        return this.db.list('/notifications', function (ref) { return ref.orderByChild('receiver').equalTo(account_id).limitToFirst(50); }).valueChanges();
    };
    FirebaseService.prototype.updateSeen = function (account_id) {
        var data = { seen: true };
        return this.db.list('/notifications', function (ref) { return ref.orderByChild('receiver').equalTo(account_id).limitToFirst(50); }).update('seen', data);
    };
    FirebaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirebaseService_Factory() { return new FirebaseService(i0.ɵɵinject(i1.AngularFireDatabase)); }, token: FirebaseService, providedIn: "root" });
    return FirebaseService;
}());
export { FirebaseService };
