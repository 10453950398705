<ion-list no-padding no-margin>
    <ion-list-header color="dark">
      <ion-label class="fw700">Notifications</ion-label>
    </ion-list-header>

    <ion-item color="primary" class="text-10">
      <ion-icon name="mail" color="light"></ion-icon>
      <ion-label>New Offer 25% OFF</ion-label>
    </ion-item>

    <ion-item color="light" class="text-10">
      <ion-icon name="mail-open" color="primary"></ion-icon>
      <ion-label>New Offer 15% OFF by month!</ion-label>
    </ion-item>

    <ion-item color="light" class="text-10">
      <ion-icon name="mail-open" color="primary"></ion-icon>
      <ion-label>New Offer 10% OFF by month!</ion-label>
    </ion-item>

    <ion-item color="primary" class="text-10">
      <ion-icon name="mail" color="light"></ion-icon>
      <ion-label>New Offer 35% OFF</ion-label>
    </ion-item>

  </ion-list>