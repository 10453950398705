import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PopoverController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-new-visitor',
  templateUrl: './new-visitor.component.html',
  styleUrls: ['./new-visitor.component.scss']
})
export class NewVisitorComponent implements OnInit {
  public visitor: any = null;
  public form: FormGroup;

  constructor(
    private authService: AuthService,
    public popoverController: PopoverController,
    public navParams: NavParams,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    if (this.navParams.data.visitor) {
      this.visitor = this.navParams.data.visitor;
      this.form = new FormGroup({
        firstName: new FormControl(this.visitor.firstName, {
          updateOn: 'change',
          validators: [Validators.required, Validators.minLength(1)]
        }),
        lastName: new FormControl(this.visitor.lastName, {
          updateOn: 'change',
          validators: [Validators.required, Validators.minLength(1)]
        }),
        phone: new FormControl(this.visitor.phone, {
          updateOn: 'change',
          validators: [Validators.required, Validators.pattern('^[+]{1}[0-9]{3}[0-9]{5,15}$')]
        }),
        gender: new FormControl(this.visitor.gender, {
          updateOn: 'change',
          validators: [Validators.required, Validators.minLength(2)]
        }),
        address: new FormControl(this.visitor.address, {
          updateOn: 'change',
          validators: [Validators.required]
        }),
        email: new FormControl(this.visitor.email, {
          updateOn: 'change',
          validators: [Validators.email]
        }),
      });
    } else {
      this.form = this.formBuilder.group({
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        email: [null, (Validators.required, Validators.email)],
        phone: ['+234', (Validators.required, Validators.pattern('^[+]{1}[0-9]{3}[0-9]{5,15}$'))],
        address: [null, Validators.required],
        gender: [null, Validators.required],
      })
    }
  }

  public isInputValid(input: string): boolean {
    return this.form.get(input).invalid && this.form.get(input).touched
  }

  public submitForm() {
    let userEmail;
    this.authService.userInfo.subscribe(user => {
      userEmail = user.email;
    })
    this.visitor = {
      'firstName': this.form.get('firstName').value,
      'lastName': this.form.get('lastName').value,
      'email': this.form.get('email').value || userEmail,
      'phone': this.form.get('phone').value,
      'address': this.form.get('address').value,
      'gender': this.form.get('gender').value,
    }
    this.dismissPopover()
  }

  private dismissPopover() {
    this.popoverController.dismiss(this.visitor);
  }

}
