/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dependents-popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./dependents-popover.component";
var styles_DependentsPopoverComponent = [i0.styles];
var RenderType_DependentsPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DependentsPopoverComponent, data: {} });
export { RenderType_DependentsPopoverComponent as RenderType_DependentsPopoverComponent };
function View_DependentsPopoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["No record found"]))], null, null); }
function View_DependentsPopoverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-list-header", [], null, null, null, i2.View_IonListHeader_0, i2.RenderType_IonListHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonListHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Select Recipient"]))], null, null); }
function View_DependentsPopoverComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-icon", [["color", "success"], ["name", "checkmark-circle-outline"], ["slot", "end"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(1, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null)], function (_ck, _v) { var currVal_0 = "success"; var currVal_1 = "checkmark-circle-outline"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DependentsPopoverComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-item", [["button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectRecipient(_co.user) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { button: [0, "button"] }, null), (_l()(), i1.ɵted(-1, 0, ["Myself "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DependentsPopoverComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.selectedRecipient.indexOf(_co.user) > (0 - 1)) || _co.userSelected); _ck(_v, 4, 0, currVal_1); }, null); }
function View_DependentsPopoverComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-icon", [["color", "success"], ["name", "checkmark-circle-outline"], ["slot", "end"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(1, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null)], function (_ck, _v) { var currVal_0 = "success"; var currVal_1 = "checkmark-circle-outline"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DependentsPopoverComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-item", [["button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectRecipient(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { button: [0, "button"] }, null), (_l()(), i1.ɵted(2, 0, ["", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DependentsPopoverComponent_6)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_2 = (_co.selectedRecipient.indexOf(_v.context.$implicit) != (0 - 1)); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = ((_v.context.$implicit.firstName + " ") + _v.context.$implicit.lastName); _ck(_v, 2, 0, currVal_1); }); }
export function View_DependentsPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ion-list", [], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(1, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DependentsPopoverComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DependentsPopoverComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DependentsPopoverComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DependentsPopoverComponent_5)), i1.ɵdid(9, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "ion-button", [["expand", "block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissPopover() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(11, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.recipients && !_co.user); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.recipients; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.user; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.recipients; _ck(_v, 9, 0, currVal_3); var currVal_4 = "block"; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_DependentsPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dependents-popover", [], null, null, null, View_DependentsPopoverComponent_0, RenderType_DependentsPopoverComponent)), i1.ɵdid(1, 114688, null, 0, i5.DependentsPopoverComponent, [i3.PopoverController, i3.NavParams], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DependentsPopoverComponentNgFactory = i1.ɵccf("app-dependents-popover", i5.DependentsPopoverComponent, View_DependentsPopoverComponent_Host_0, {}, {}, []);
export { DependentsPopoverComponentNgFactory as DependentsPopoverComponentNgFactory };
