<form [formGroup]="form" id="visitor" (submit)='submitForm()'>
  <ion-list-header>
    <span *ngIf="visitor">EDIT VISITOR</span>
    <span *ngIf="!visitor">NEW VISITOR</span>
  </ion-list-header>
  <ion-item>
    <ion-label position="floating">First Name <span style="color: red;">*</span></ion-label>
    <ion-input placeholder="First Name" formControlName="firstName" required></ion-input>
  </ion-item>
  <ion-item *ngIf="isInputValid('firstName')" lines="none">
    <ion-label class="error-label">
      First name field is required
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label position="floating">Last Name <span style="color: red;">*</span></ion-label>
    <ion-input placeholder="Last Name" formControlName="lastName" required></ion-input>
  </ion-item>
  <ion-item *ngIf="isInputValid('lastName')" lines="none">
    <ion-label class="error-label">
      Last name field is required
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label position="floating">Email </ion-label>
    <ion-input placeholder="Email Address" formControlName="email"></ion-input>
  </ion-item>
  <ion-item *ngIf="isInputValid('email')" lines="none">
    <ion-label class="error-label">
      Please ensure the email is valid
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label position="floating">Phone <span style="color: red;">*</span></ion-label>
    <ion-input placeholder="+2348xxxxxxxxx" formControlName="phone" required></ion-input>
  </ion-item>
  <ion-item *ngIf="isInputValid('phone')" lines="none">
    <ion-label class="error-label">
      Phone Number must include international code e.g. (+2349066030644)
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label position="floating">Address <span style="color: red;">*</span></ion-label>
    <ion-textarea placeholder="Address" formControlName="address" required></ion-textarea>
  </ion-item>
  <ion-item *ngIf="isInputValid('address')" lines="none">
    <ion-label class="error-label">
      Address field is required
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label position="floating">Gender <span style="color: red;">*</span></ion-label>
    <ion-select name="gender" formControlName="gender" required>
      <ion-select-option>Female</ion-select-option>
      <ion-select-option>Male</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item *ngIf="isInputValid('gender')" lines="none">
    <ion-label class="error-label">
      Gender field is required
    </ion-label>
  </ion-item>
  <ion-button expand="full" color="primary" type="submit" [disabled]="form.invalid || !form.dirty">Done
  </ion-button>
</form>