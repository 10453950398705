import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
var UpdateServiceService = /** @class */ (function () {
    function UpdateServiceService(updates) {
        this.updates = updates;
        if (updates.isEnabled) {
            interval(6 * 60 * 60).subscribe(function () { return updates.checkForUpdate()
                .then(function () { return console.log('checking for updates'); }); });
        }
    }
    UpdateServiceService.prototype.checkForUpdates = function () {
        var _this = this;
        this.updates.available.subscribe(function (event) { return _this.promptUser(); });
    };
    UpdateServiceService.prototype.promptUser = function () {
        console.log('updating to new version');
        this.updates.activateUpdate().then(function () { return document.location.reload(); });
    };
    UpdateServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateServiceService_Factory() { return new UpdateServiceService(i0.ɵɵinject(i1.SwUpdate)); }, token: UpdateServiceService, providedIn: "root" });
    return UpdateServiceService;
}());
export { UpdateServiceService };
