export class User {
    constructor(
        public name: string,
        private _token: string,
        private tokenExpirationDate: Date,
        public role: string,
        public access_level: string,
        private _isAdmin: boolean,
        public account_id: string,
        public estateName: string,
        public admin: any,
        public email: string

    ) { }

    get token() {
        if (!this.tokenExpirationDate || this.tokenExpirationDate <= new Date()) {
            return null;
        }
        return this._token;
    }

    get isAdmin() {
        if (!this.isAdmin) {
            return null;
        }
        return this._isAdmin;
    }

    get tokenDuration() {
        if (!this.token) {
            return 0;
        }
        return this.tokenExpirationDate.getTime() - new Date().getTime();
    }
}
