/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-filter.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./search-filter.page";
import * as i3 from "@ionic/angular";
var styles_SearchFilterPage = [i0.styles];
var RenderType_SearchFilterPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchFilterPage, data: {} });
export { RenderType_SearchFilterPage as RenderType_SearchFilterPage };
export function View_SearchFilterPage_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_SearchFilterPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-filter", [], null, null, null, View_SearchFilterPage_0, RenderType_SearchFilterPage)), i1.ɵdid(1, 114688, null, 0, i2.SearchFilterPage, [i3.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchFilterPageNgFactory = i1.ɵccf("app-search-filter", i2.SearchFilterPage, View_SearchFilterPage_Host_0, {}, {}, []);
export { SearchFilterPageNgFactory as SearchFilterPageNgFactory };
