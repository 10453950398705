import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, switchMap, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AuthGuard.prototype.canLoad = function (route, segments) {
        var _this = this;
        return this.authService.userIsAuthenticated
            .pipe(take(1), switchMap(function (isAuthenticated) {
            if (!isAuthenticated) {
                return _this.authService.autoLogin();
            }
            else {
                return of(isAuthenticated);
            }
        }), tap(function (isAuthenticated) {
            if (!isAuthenticated) {
                return _this.router.navigateByUrl('/login');
            }
        }));
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
