import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.scss']
})
export class VisitorsComponent implements OnInit {
  visitors;
  selectedVisitors = [];
  selectedVisitorsName = null;
  constructor(
    public popoverController: PopoverController,
    public commonService: CommonService,
    public navParams: NavParams) { }

  ngOnInit() {
    if (this.navParams.data.selectedVisitors && this.navParams.data.selectedVisitors.length > 0) {
      this.selectedVisitors = this.navParams.data.selectedVisitors;
    }
    if (this.navParams.data.visitors.visitors.length > 0) {
      this.visitors = this.navParams.data.visitors.visitors;
    } else {
      this.visitors = null;
    }
  }

  selectVisitor(visitor) {
    const rec = this.selectedVisitors.findIndex(item => item.visitorId === visitor._id);
    if (rec === -1) {
      let tempVisitor = {
        visitorId: visitor._id,
        firstName: visitor.firstName,
        lastName: visitor.lastName,
        email: visitor.email,
        phone: visitor.phone,
        address: visitor.address,
        gender: visitor.gender,
      }
      this.selectedVisitors.push(tempVisitor);
    } else {
      this.selectedVisitors.splice(rec, 1);
    }
  }

  findVisitor(visitor) {
    let found = this.selectedVisitors.find(item => item.visitorId == visitor._id)
    if (found) {
      return true
    }
  }

  dismissPopover() {
    this.popoverController.dismiss(this.selectedVisitors);
  }

}
