import { Injectable } from '@angular/core';
// import * as firebase from 'firebase/app';
// import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase  } from '@angular/fire/database'
import { Observable } from 'rxjs';
import { VisitorCheckin } from '../interfaces/visitor-checkin';
import { ArrivalNotice } from '../interfaces/arrival-notice';
import { map } from 'rxjs/operators';
import { Notifications } from '../interfaces/notifications';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  notifications: Observable<any[]>;

  constructor(private db: AngularFireDatabase) {
    this.notifications = this.db.list<any>('notifications').valueChanges();
    // this.visitorCheckins = this.visitorCheckinsCollection.snapshotChanges().pipe(
    //   map(actions => {
    //     return actions.map(a => {
    //       const data = a.payload.doc.data();
    //       const id = a.payload.doc.id;
    //       return { id, ...data };
    //     });
    //   })
    // );



  }

  /**
   * create
   */

  public getNotificationCount(account_id): Observable<any> {
    return this.db.list('/notifications', ref => ref.orderByChild('receiver').equalTo(account_id)).snapshotChanges();
  }

  public getNotificationContent(account_id): Observable<any> {
    return this.db.list('/notifications', ref => ref.orderByChild('receiver').equalTo(account_id).limitToFirst(50)).valueChanges();
  }

  public updateSeen(account_id) {
    const data = {seen: true};
    return this.db.list('/notifications', ref => ref.orderByChild('receiver').equalTo(account_id).limitToFirst(50)).update('seen', data);
  }
}
