/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./popup.component";
var styles_PopupComponent = [i0.styles];
var RenderType_PopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupComponent, data: {} });
export { RenderType_PopupComponent as RenderType_PopupComponent };
export function View_PopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["id", "ios-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To install "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["class", "gh-logo"], ["src", "../../../assets/img/gatehouse-logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" on your iPhone:"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tap the share button"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["src", "../../../assets/icon/ei_share-apple.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "p", [["class", "instruction"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tap "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\"Add to Home Screen\""])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["src", "../../../assets/icon/fa_plus-square-o.svg"]], null, null, null, null, null))], null, null); }
export function View_PopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup", [], null, null, null, View_PopupComponent_0, RenderType_PopupComponent)), i1.ɵdid(1, 114688, null, 0, i2.PopupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupComponentNgFactory = i1.ɵccf("app-popup", i2.PopupComponent, View_PopupComponent_Host_0, {}, {}, []);
export { PopupComponentNgFactory as PopupComponentNgFactory };
