export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDlVlS_tRjffZ9FxtI8YjpG3AcFLtgNed0',
    authDomain: 'gatehouse-e5521.firebaseapp.com',
    databaseURL: 'https://gatehouse-e5521.firebaseio.com',
    projectId: 'gatehouse-e5521',
    storageBucket: 'gatehouse-e5521.appspot.com',
    messagingSenderId: '142295117943',
    appId: '1:142295117943:web:cd4cf04a0238470c2b1672',
    vapidKey: 'BCTX1aaFkRllEJ5p-POhNB0Hs3hUqlzVS4B9sV5eV38VAfzrtjpZBjMSPxLvOnU1nAlkx98k0dNQO08KM6nr5CE'
  },
  paystack_pk: 'pk_live_9d88577c9e7edf9385d60059af36d8bc5faf11fb'
};
