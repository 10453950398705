import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  baseUrl = 'https://www.gate-house.com/api';
  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getDashboardInfo(filter, today): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/index/dashboard/?filter=' + filter + '&today=' + today, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            if (response.property) {
              this.authService.setEstateData(response.property);
            }
            return response;
          }

          catchError(this.handleError);
        })
      );
  }

  getUserInfo(): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/propertyAdmin/profile', httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  changePassword(data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/propertyAdmin/change-password', data, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            // console.log(response);
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  fetchLocalInfo() {
    const user = localStorage.getItem('user');
    return JSON.parse(user);
  }

  getVisitors(): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/my-visitors', httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }

          catchError(this.handleError);
        })
      );
  }

  allVisitors(offset?): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/get-visitors/?offset=' + offset, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            // console.log(response.success.visitors)
            return response;
          }

          catchError(this.handleError);
        })
      );
  }

  newBooking(data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/visitors/new-booking?createV=' + data.createV, data, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            // console.log(response);
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  newBulkBooking(data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/visitors/new-bulk-booking', data, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            // console.log(response);
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  createGatepass(data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/visitors/new-gatepass', data, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            // console.log(response);
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  getVisitor(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/oneVisitor/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.visitor;
          }
          catchError(this.handleError);
        })
      );
  }

  getVisitorPasses(filter, aDate, offset?): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/get-bookings/?filter=' + filter + '&today=' + aDate, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.bookings.docs;
          }
          catchError(this.handleError);
        })
      );
  }

  morePasses(filter, aDate, offset): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/get-bookings/?filter=' + filter + '&today=' + aDate + '&offset=' + offset,
      httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.bookings.docs;
          }
          catchError(this.handleError);
        })
      );
  }

  morePendingBookings(filter, aDate, offset): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/get-pending-bookings/?filter=' + filter +
      '&today=' + aDate + '&offset=' + offset, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.bookings.docs;
          }
          catchError(this.handleError);
        })
      );
  }

  getGatepasses(filter, aDate): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/get-gatePass/?filter=' + filter + '&today=' + aDate, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  moreGatepasses(filter, aDate, offset): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/more-gatePass/?filter=' + filter + '&today=' + aDate + '&offset=' + offset,
      httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.gatePasses.docs;
          }
          catchError(this.handleError);
        })
      );
  }

  morePendingGatepasses(filter, aDate, offset): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/more-pending-gatePasses/?filter=' + filter + '&today=' + aDate + '&offset='
      + offset, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.pendingGatePasses.docs;
          }
          catchError(this.handleError);
        })
      );
  }

  moreDependentGatepasses(filter, aDate, offset): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/more-pending-gatePass-requests/?filter='
      + filter + '&today=' + aDate + '&offset=' + offset, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.pendingGatePasses.docs;
          }
          catchError(this.handleError);
        })
      );
  }

  getVisitorPass(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/oneBooking/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  approveDeclineDependentBooking(id, status): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/approve-booking/' + id + '?status=' + status, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  editPass(data, id): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/visitors/edit-booking/' + id, data, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.booking;
          }
          catchError(this.handleError);
        })
      );
  }

  getPendingBooking(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/onePendingBooking/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.booking;
          }
          catchError(this.handleError);
        })
      );
  }

  editPendingPass(data, id): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/visitors/edit-pending-booking/' + id, data, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.booking;
          }
          catchError(this.handleError);
        })
      );
  }

  getGatePass(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/oneGatePass/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  editGatePass(data, id): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/visitors/edit-gatePass/' + id, data, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  getPendingGatePass(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/onePendingGatePass/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.gatePass;
          }
          catchError(this.handleError);
        })
      );
  }

  editPendingGatePass(data, id): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/visitors/edit-pending-gatePass/' + id, data, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  approveDeclineDependentPass(id, status): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/approve-gatepass/' + id + '?status=' + status, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  adminApproveDeclineGatePass(id, status): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/admin-approve-gatepass/' + id + '?status=' + status, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  createDependent(dependent): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/propertyAdmin/add-dependant/', dependent, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  adminCreateDependent(dependent, id): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/propertyAdmin/new-dependant/' + id, dependent, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  allResidents(offset?): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/propertyAdmin/all-residents/?offset=' + offset, httpOptions)
      .pipe(
        map(response => {
          if (response.residents) {
            // console.log(response)
            return response.residents;
          }

          catchError(this.handleError);
        })
      );
  }

  getResident(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/propertyAdmin/one-resident/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response.resident) {
            return response.resident;
          }
          catchError(this.handleError);
        })
      );
  }

  searchResidents(searchQuery): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/propertyAdmin/search-residents/', { searchQuery: searchQuery })
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      )
  }

  searchDependents(searchQuery): Observable<any> {
    return this.httpClient.post(this.baseUrl + '/propertyAdmin/search-dependents/', { searchQuery: searchQuery })
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      )
  }

  editResident(id, data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/propertyAdmin/editResident/' + id, data)
      .pipe(
        map(response => {
          if (response) {
            // console.log(response);
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  deleteResident(id): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/propertyAdmin/deleteResident/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  allDependents(offset?): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/propertyAdmin/my-dependants/?offset=' + offset, httpOptions)
      .pipe(
        map(response => {
          if (response.dependants) {
            // console.log(response)
            return response.dependants;
          }

          catchError(this.handleError);
        })
      );
  }

  getPropertyDependents(offset?): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/propertyAdmin/all-property-dependants/?offset=' + offset, httpOptions)
      .pipe(
        map(response => {
          if (response.dependants) {
            // console.log(response)
            return response.dependants;
          }
          catchError(this.handleError);
        })
      );
  }

  residentDependents(id, offset?): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/propertyAdmin/view-dependants/' + id + '/?offset=' + offset, httpOptions)
      .pipe(
        map(response => {
          if (response.dependants) {
            // console.log(response)
            return response.dependants;
          }

          catchError(this.handleError);
        })
      );
  }

  getDependent(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/propertyAdmin/one-dependant/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response.dependant) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  editDependent(id, data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/propertyAdmin/edit-dependant/' + id, data, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            // console.log(response);
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  deleteDependent(id): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/propertyAdmin/deleteDependant/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  generateDependentPass(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/my-dependent/pass/generate/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  getPendingDependentPasses(filter, today): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/get-pending-bookings/?filter=' + filter + '&today=' + today, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  deleteDependentPass(id): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/my-dependent/pass/delete/' + id, httpOptions)
      .pipe(
        map(response => {
          console.log(response);
          if (response.success) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  revokeDependentPass(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/my-dependent/pass/revoke/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  resetPassword(email): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/index/password-reset/', email, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  revokeVisitorPass(id): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/visitors/pass/revoke/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  deleteVisitorPass(id): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/visitors/cancel-booking/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  deletePendingVisitorPass(id): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/visitors/delete-pendingBooking/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  deleteGatePass(id): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/visitors/cancel-gatepass/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  deleteDependentGatePass(id): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/visitors/delete-pendingGatepass/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  filterResidentCheckIns(data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/residents/check-ins', data, httpOptions)
      .pipe(
        map(response => {
          if (response.checkIns) {
            return response.checkIns;
          }
          catchError(this.handleError);
        })
      );
  }

  getDependentsCheckIns(): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/dependents/check-ins', httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response.checkIns;
          }
          catchError(this.handleError);
        })
      );
  }

  filterDependentsCheckIns(data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/dependents/check-ins', data, httpOptions)
      .pipe(
        map(response => {
          if (response.checkIns) {
            return response.checkIns;
          }
          catchError(this.handleError);
        })
      );
  }

  filterVisitorPasses(data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/visitors/bookings', data, httpOptions)
      .pipe(
        map(response => {
          if (response.passes) {
            return response.passes;
          }
          catchError(this.handleError);
        })
      );
  }

  deleteVisitor(id): Observable<any> {
    return this.httpClient.delete<any>(this.baseUrl + '/visitors/deleteVisitor/' + id, httpOptions)
      .pipe(
        map(response => {
          if (response) {
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  editVisitor(id, data): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/visitors/editVisitor/' + id, data)
      .pipe(
        map(response => {
          if (response) {
            // console.log(response);
            return response;
          }
          catchError(this.handleError);
        })
      );
  }

  getResidentApartment(): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/my-apartment')
      .pipe(
        map(response => {
          return response;

          catchError(this.handleError);
        })
      )
  }

  removeDevice(deviceId): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/index/remove-device', { deviceId: deviceId }, httpOptions)
      .pipe(
        map(response => {
          console.log(response);
          catchError(this.handleError);
        })
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError(error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
