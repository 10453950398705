var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ModalController, PopoverController, LoadingController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { DatePipe } from '@angular/common';
import { VisitorsComponent } from 'src/app/components/visitors/visitors.component';
import { NewVisitorComponent } from 'src/app/components/new-visitor/new-visitor.component';
var NewVisitorPassPage = /** @class */ (function () {
    function NewVisitorPassPage(modalCtrl, commonService, datePipe, router, popoverController, loadingCtrl, alertCtrl) {
        this.modalCtrl = modalCtrl;
        this.commonService = commonService;
        this.datePipe = datePipe;
        this.router = router;
        this.popoverController = popoverController;
        this.loadingCtrl = loadingCtrl;
        this.alertCtrl = alertCtrl;
        this.createVisitor = false;
        this.selectedVisitors = [];
        this.newVisitors = [];
        this.showEndDate = true;
        this.today = new Date(Date.now());
        this.currDate = this.datePipe.transform(this.today, 'yyyy-MM-dd').toString();
        this.currTime = this.datePipe.transform(this.today, 'HH:mm').toString();
    }
    NewVisitorPassPage.prototype.ngOnInit = function () {
        var _this = this;
        if (!window.history.state.modal) {
            var modalState = { modal: true };
            history.pushState(modalState, null);
        }
        this.commonService.getVisitors()
            .subscribe(function (response) {
            _this.visitors = response;
        }, function (error) {
            alert(error);
        });
    };
    NewVisitorPassPage.prototype.closeModal = function () {
        this.modalCtrl.dismiss();
    };
    NewVisitorPassPage.prototype.toggleCreateVisitor = function () {
        this.createVisitor = !this.createVisitor;
    };
    NewVisitorPassPage.prototype.showVisitors = function () {
        return __awaiter(this, void 0, void 0, function () {
            var popover;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverController.create({
                            component: VisitorsComponent,
                            componentProps: {
                                visitors: this.visitors,
                                selectedVisitors: this.selectedVisitors
                            }
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss()
                            .then(function (result) {
                            if (result['data'] !== undefined) {
                                _this.selectedVisitors = result['data'];
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    NewVisitorPassPage.prototype.removeVisitor = function (id) {
        var record = this.selectedVisitors.findIndex(function (item) { return item.visitorId === id; });
        if (this.selectedVisitors.length > 0 && record > -1) {
            this.selectedVisitors.splice(record, 1);
        }
    };
    NewVisitorPassPage.prototype.addNewVisitor = function () {
        return __awaiter(this, void 0, void 0, function () {
            var popover;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverController.create({
                            component: NewVisitorComponent,
                            cssClass: 'visitor-popup',
                        })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss()
                            .then(function (result) {
                            if (result['data'] !== undefined) {
                                _this.createVisitor = true;
                                _this.newVisitors.push(result['data']);
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    NewVisitorPassPage.prototype.removeNewVisitor = function (id) {
        var record = this.newVisitors.findIndex(function (item) { return item.visitorId === id; });
        if (this.newVisitors.length > 0 && record > -1) {
            this.newVisitors.splice(record, 1);
        }
    };
    NewVisitorPassPage.prototype.hideShowEndDate = function (event) {
        var selectedBookingType = event.target.value;
        if (selectedBookingType !== 'multiple-entry') {
            this.showEndDate = false;
        }
        else {
            this.showEndDate = true;
        }
    };
    NewVisitorPassPage.prototype.newBooking = function (data) {
        var _this = this;
        this.success = false;
        this.isError = false;
        this.isFailure = false;
        if (!data.valid) {
            this.showAlert('All fields must be filled out');
            return;
        }
        else {
            if (data.controls.new_visitor && data.controls.new_visitor.invalid) {
                this.showAlert('Please fill out all visitor details correctly. All fields are required');
                return;
            }
            else {
                if (data.value.from_date > data.value.to_date) {
                    this.showAlert('From date cannot be greater than to date');
                    return;
                }
                else {
                    var create_new_visitor_1 = this.createVisitor;
                    var formattedFromDate = this.datePipe.transform(data.value.from_date, 'yyyy-MM-dd');
                    var formattedToDate = this.datePipe.transform(data.value.to_date, 'yyyy-MM-dd');
                    var formattedFromTime = this.datePipe.transform(data.value.from_time, 'HH:mm');
                    var conditionalToDate = null;
                    if (data.value.pass_type === 'multiple-entry') {
                        conditionalToDate = formattedToDate;
                    }
                    else {
                        conditionalToDate = formattedFromDate;
                    }
                    if (create_new_visitor_1 === true && this.newVisitors.length == 1 && this.selectedVisitors.length <= 0) {
                        var newVisitor = this.newVisitors[0];
                        this.newData = {
                            createV: create_new_visitor_1,
                            fromDate: formattedFromDate,
                            toDate: conditionalToDate,
                            arrivalTime: formattedFromTime,
                            numberInParty: data.value.number_party,
                            bookingType: data.value.pass_type,
                            firstName: newVisitor.firstName,
                            lastName: newVisitor.lastName,
                            email: newVisitor.email,
                            phone: newVisitor.phone,
                            address: newVisitor.address,
                            gender: newVisitor.gender,
                            plateNumber: data.value.plateNumber,
                            note: data.value.note,
                            visitorId: null,
                            notification: data.value.notification
                        };
                    }
                    else {
                        if (this.selectedVisitors === null) {
                            return;
                        }
                        else {
                            this.newData = {
                                oldVisitors: this.selectedVisitors,
                                newVisitors: this.newVisitors,
                                visitorCsv: null,
                                fromDate: formattedFromDate,
                                toDate: conditionalToDate,
                                arrivalTime: formattedFromTime,
                                numberInParty: data.value.number_party,
                                bookingType: data.value.pass_type,
                                plateNumber: data.value.plateNumber,
                                note: data.value.note,
                                notification: data.value.notification
                            };
                        }
                    }
                    this.isLoading = true;
                    this.loadingCtrl
                        .create({ keyboardClose: true, message: 'Please Wait...' })
                        .then(function (loadingEl) {
                        loadingEl.present();
                        if (create_new_visitor_1 && _this.newVisitors.length == 1 && _this.selectedVisitors.length <= 0) {
                            _this.commonService.newBooking(_this.newData)
                                .subscribe(function (booking) {
                                if (booking.booking || booking.pendingBooking) {
                                    _this.success = true;
                                    _this.isLoading = false;
                                    loadingEl.dismiss();
                                    _this.router.navigateByUrl("all-passes");
                                    _this.sendWhatsapp(booking);
                                    _this.closeModal();
                                }
                            }, function (error) {
                                _this.isError = true;
                                _this.isLoading = false;
                                loadingEl.dismiss();
                                _this.showAlert(error);
                                _this.errorMessage = 'An error has occured. Please check your data and try again';
                            });
                        }
                        else {
                            _this.commonService.newBulkBooking(_this.newData)
                                .subscribe(function (booking) {
                                if (booking.success) {
                                    _this.success = true;
                                    _this.isLoading = false;
                                    loadingEl.dismiss();
                                    _this.router.navigateByUrl("all-passes");
                                    _this.showAlert('Operation Successful!\nClick on the share button to send booking info to the recipient on WhatsApp.');
                                }
                            }, function (error) {
                                _this.isError = true;
                                _this.isLoading = false;
                                loadingEl.dismiss();
                                _this.showAlert(error);
                                _this.errorMessage = 'An error has occured. Please check your data and try again';
                            });
                        }
                    });
                }
            }
        }
    };
    NewVisitorPassPage.prototype.showAlert = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: 'Heads Up!',
                            message: message,
                            buttons: ['Okay']
                        }).then(function (alertEl) {
                            alertEl.present();
                            _this.closeModal();
                        })];
                    case 1:
                        alert = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    NewVisitorPassPage.prototype.sendWhatsapp = function (bookingData) {
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: 'Success!',
                            message: 'Pass Created Successfully. Click Send to WhatsApp to attempt to send this booking info to the recipient on WhatsApp',
                            buttons: [
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: function () {
                                        _this.modalCtrl.dismiss();
                                    }
                                }, {
                                    text: 'Send to WhatsApp',
                                    handler: function (data) { return __awaiter(_this, void 0, void 0, function () {
                                        var whatsappMessage, phoneNum, whatsappUrl;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    whatsappMessage = encodeURI('Hi ' + bookingData.booking.firstName + ' ' + bookingData.booking.lastName +
                                                        ', I have just booked you into ' + bookingData.estate + '. Your Booking code is ' + bookingData.booking.bookingCode +
                                                        '. Please Come along with the QR image in the link below or your booking Code. Thank You.\n ' + bookingData.q);
                                                    phoneNum = bookingData.booking.phone;
                                                    whatsappUrl = 'https://api.whatsapp.com/send?phone=' + phoneNum + '&text=' + whatsappMessage;
                                                    return [4 /*yield*/, window.open(whatsappUrl)];
                                                case 1:
                                                    _a.sent();
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return NewVisitorPassPage;
}());
export { NewVisitorPassPage };
