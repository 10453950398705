import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
// const httpOptions = {
//     headers: new HttpHeaders({
//       'Content-Type': 'application/json',
//       'Authorization': "Bearer "+localStorage.getItem('token')
//     })
//   };
@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnInit {
    currentUser: string;
    constructor(private httpClient: HttpClient, public router: Router, private authService: AuthService) {

    }
    ngOnInit() {

    }
   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService.token.pipe(take(1)).subscribe(token => {
        // console.log(token);
        return this.currentUser = token;
    });
       if (this.currentUser) {
         //  console.log(this.currentUser, 'token');
        const clonedReq = req.clone({headers: req.headers.append('x-access-token', this.currentUser)});
           return next.handle(clonedReq).pipe(catchError(err => {
            if (err.status === 302) {
               alert(err.error.message);

               this.router.navigate(['/change-password']);
            }if (err.status === 401) {
               // alert(err.error.message);

               this.router.navigate(['/access-denied']);
                // auto logout if 401 response returned from api
               //  this.loginService.logOut();
               //  location.reload(true);
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));

       }
       return next.handle(req);
   }

}
