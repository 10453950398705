import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Modal Pages
import { ImagePageModule } from './pages/modal/image/image.module';
import { SearchFilterPageModule } from './pages/modal/search-filter/search-filter.module';

// Components
import { NotificationsComponent } from './components/notifications/notifications.component';
import { AuthInterceptor } from './common/token.interceptor';
import { NewVisitorPassPageModule } from './pages/modal/new-visitor-pass/new-visitor-pass.module';
import { DatePipe } from '@angular/common';
// import { VisitorsComponent } from './components/visitors/visitors.component';
import { VisitorsModule } from './components/visitors/visitors.module';
import { AngularFireModule } from '@angular/fire';
// import { environment } from 'src/environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NewAlertPageModule } from './pages/modal/new-alert/new-alert.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment.prod';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { EditGatepassPipe } from './pages/passes/edit-gatepass.pipe';
// import { DependentsPopoverComponent } from './components/dependents-popover/dependents-popover.component';
import { DependentsPopoverModule } from './components/dependents-popover/dependents-popover.module';
import { Angular4PaystackModule } from 'angular4-paystack';
import { PopupComponent } from './components/popup/popup.component';
import { NewVisitorComponent } from './components/new-visitor/new-visitor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AppComponent, NotificationsComponent, EditGatepassPipe, PopupComponent, NewVisitorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ImagePageModule,
    SearchFilterPageModule,
    NewVisitorPassPageModule,
    NewAlertPageModule,
    VisitorsModule,
    FormsModule,
    ReactiveFormsModule,
    DependentsPopoverModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    Angular4PaystackModule.forRoot(environment.paystack_pk),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  entryComponents: [NotificationsComponent, PopupComponent, NewVisitorComponent],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: FirestoreSettingsToken, useValue: {}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
