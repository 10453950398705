var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, BehaviorSubject, from } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { User } from '../pages/login/user.model';
import { Plugins } from '@capacitor/core';
import * as firebase from 'firebase/app';
import { FirebaseService } from './firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./firebase.service";
import * as i3 from "@angular/fire/auth";
var Device = Plugins.Device;
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var AuthService = /** @class */ (function () {
    function AuthService(httpClient, firebaseService, afAuth) {
        var _this = this;
        this.httpClient = httpClient;
        this.firebaseService = firebaseService;
        this.afAuth = afAuth;
        this._user = new BehaviorSubject(null);
        this.baseUrl = 'https://www.gate-house.com/api';
        Device.getInfo().then(function (data) {
            _this.deviceId = data.uuid;
        });
    }
    Object.defineProperty(AuthService.prototype, "userIsAuthenticated", {
        get: function () {
            return this._user.asObservable()
                .pipe(map(function (user) {
                if (user) {
                    return !!user.token;
                }
                else {
                    return false;
                }
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "userInfo", {
        get: function () {
            return this._user.asObservable()
                .pipe(map(function (user) {
                if (user) {
                    return user;
                }
                else {
                    return null;
                }
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "token", {
        get: function () {
            return this._user.asObservable()
                .pipe(map(function (user) {
                if (user) {
                    return user.token;
                }
                else {
                    return null;
                }
            }));
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.autoLogin = function () {
        var _this = this;
        return from(Plugins.Storage.get({ key: 'authData' }))
            .pipe(map(function (storedData) {
            if (!storedData || !storedData.value) {
                return null;
            }
            var parsedData = JSON.parse(storedData.value);
            var expirationTime = new Date(parsedData.expirationTime);
            if (expirationTime <= new Date()) {
                return null;
            }
            var user = new User(parsedData.name, parsedData.token, expirationTime, parsedData.role, parsedData.access_level, !!parsedData.isAdmin, parsedData.account_id, parsedData.estateName, parsedData.admin, parsedData.email);
            return user;
        }), tap(function (user) {
            if (user) {
                _this._user.next(user);
                _this.autologout(user.tokenDuration);
            }
        }), map(function (user) {
            return !!user;
        }));
    };
    AuthService.prototype.login = function (data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/index/login', data, httpOptions)
            .pipe(tap(this.setUserData.bind(this)), map(function (response) {
            if (response.success) {
                _this.firebaseLoginOrRegister(data);
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    AuthService.prototype.fcmToken = function (data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/index/get-fcm-token', data, httpOptions)
            .pipe(map(function (response) {
            if (response.success) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    AuthService.prototype.firebaseLoginOrRegister = function (data) {
        return new Promise(function (resolve, reject) {
            resolve(firebase.auth().signInWithEmailAndPassword(data.email, data.password));
        })
            .then(function (res) {
        })
            .catch(function (err) {
            if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
                return new Promise(function (resolve, reject) {
                    resolve(firebase.auth().createUserWithEmailAndPassword(data.email, data.password));
                })
                    .then(function (res) {
                })
                    .catch(function (err) {
                });
            }
        });
    };
    AuthService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            return throwError(error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
            return throwError(error);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    AuthService.prototype.getUser = function () {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/user', httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    AuthService.prototype.changePassword = function (password) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/change-password', password, httpOptions)
            .pipe(map(function (response) {
            if (response.success) {
                return true;
            }
            catchError(_this.handleError);
        }));
    };
    AuthService.prototype.logOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            var checkSupport;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        checkSupport = firebase.messaging.isSupported();
                        if (checkSupport) {
                            this.afAuth.auth.signOut().then(function (data) {
                            }).catch(function (err) {
                                console.log(err);
                            });
                        }
                        if (this.activeLogoutTimer) {
                            clearTimeout(this.activeLogoutTimer);
                        }
                        this._user.next(null);
                        return [4 /*yield*/, Plugins.Storage.remove({ key: 'authData' })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, Plugins.Storage.remove({ key: 'estateInfo' })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.removeDevice = function (token) {
        var _this = this;
        console.log('reomove', this.deviceId, token);
        return this.httpClient.post(this.baseUrl + '/index/remove-device', { deviceId: this.deviceId, fcmToken: token }, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    AuthService.prototype.ngOnDestroy = function () {
        if (this.activeLogoutTimer) {
            clearTimeout(this.activeLogoutTimer);
        }
    };
    AuthService.prototype.autologout = function (duration) {
        var _this = this;
        if (this.activeLogoutTimer) {
            clearTimeout(this.activeLogoutTimer);
        }
        this.activeLogoutTimer = setTimeout(function () {
            _this.logOut();
        }, duration / 1.3);
    };
    AuthService.prototype.setUserData = function (userData) {
        var user = userData.user;
        var estate = userData.estate;
        var expirationTime = new Date(new Date().getTime() + (+userData.expiresIn * 1000));
        var userDetails = new User(user.firstName + ' ' + user.lastName, userData.token, expirationTime, user.accessLevel, user.role, false, user.userId, estate.name, estate.admin, user.email);
        this._user.next(userDetails);
        this.autologout(userDetails.tokenDuration);
        this.storeAuthData(user.firstName + ' ' + user.lastName, userData.token, expirationTime.toISOString(), user.accessLevel, user.role, 'false', user.userId, estate.name, estate.admin, user.email);
    };
    AuthService.prototype.storeAuthData = function (name, token, expirationTime, role, access_level, isAdmin, userId, estateName, estateAdmin, email) {
        var data = JSON.stringify({
            name: name,
            token: token,
            expirationTime: expirationTime,
            role: role,
            access_level: access_level,
            isAdmin: isAdmin,
            account_id: userId,
            estateName: estateName,
            admin: estateAdmin,
            email: email
        });
        Plugins.Storage.set({ key: 'authData', value: data });
    };
    AuthService.prototype.getTokenData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var ret, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Plugins.Storage.get({ key: 'authData' })];
                    case 1:
                        ret = _a.sent();
                        if (ret) {
                            user = JSON.parse(ret.value);
                            return [2 /*return*/, user];
                        }
                        else {
                            throwError('No user found');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.getEstateData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var estate, estateInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Plugins.Storage.get({ key: 'estateInfo' })];
                    case 1:
                        estate = _a.sent();
                        if (estate) {
                            estateInfo = JSON.parse(estate.value);
                            return [2 /*return*/, estateInfo];
                        }
                        else {
                            throwError('Estate Info not found');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.setEstateData = function (estate) {
        return __awaiter(this, void 0, void 0, function () {
            var estateInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        estateInfo = this.getEstateData();
                        if (!!estateInfo) return [3 /*break*/, 1];
                        if (estate !== null) {
                            Plugins.Storage.set({ key: 'estateInfo', value: JSON.stringify(estate) });
                        }
                        return [3 /*break*/, 4];
                    case 1: return [4 /*yield*/, Plugins.Storage.remove({ key: 'estateInfo' })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, Plugins.Storage.set({ key: 'estateInfo', value: JSON.stringify(estate) })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.resetPassword = function (email) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/index/password-reset/', { 'email': email }, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FirebaseService), i0.ɵɵinject(i3.AngularFireAuth)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
