<ion-app>
  <ion-split-pane>
    <ion-menu>
      <ion-header>
        <ion-toolbar color="dark" class="user-profile">

          <ion-item margin-bottom>
            <ion-avatar slot="start" class="user-avatar">
              <img src="assets/img/avatar.png">
            </ion-avatar>
            <ion-label>
              <ion-text color="medium">
                <h1 *ngIf="userInfo"><strong>
                    {{ userInfo.name | titlecase}}
                  </strong></h1>
              </ion-text>
              <ion-text color="secondary">
                <h3 *ngIf="userInfo">
                  {{ userInfo.role }}
                </h3>
              </ion-text>
              <ion-menu-toggle class="mto" auto-hide="false">
                <a class="text08" tappable (click)="goToEditProfile()">
                  <ion-text color="medium">
                    <ion-icon name="contact"></ion-icon>
                    <strong>edit profile</strong>
                  </ion-text>
                </a>
                <ion-text color="secondary"> | </ion-text>
                <a class="text08" tappable (click)="logout()">
                  <ion-text color="medium">
                    <ion-icon name="log-out"></ion-icon>
                    <strong>logout</strong>
                  </ion-text>
                </a>
              </ion-menu-toggle>
            </ion-label>
          </ion-item>

        </ion-toolbar>
      </ion-header>

      <ion-content class="bg-profile">
        <ion-list>
          <ion-list-header color="dark">
            <ion-label>Menu</ion-label>
          </ion-list-header>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-item [routerLink]="[p.url]" color="primary">
              <ion-icon slot="start" [name]="p.icon" color="light"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
              <ion-badge color="secondary" slot="end" *ngIf="p.title == 'Notifications'">{{ notifs.length }}</ion-badge>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet main></ion-router-outlet>
  </ion-split-pane>
  <ion-fab vertical="bottom" horizontal="start" slot="fixed" *ngIf="userInfo">
    <ion-fab-button (click)="createPass()">
      <ion-icon name="person-add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="userInfo">
    <ion-fab-button (click)="createNewAlert()" color="tertiary">
      <ion-icon name="barcode"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-app>