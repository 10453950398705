<ion-list >
    <ion-item *ngIf="!recipients && !user">No record found</ion-item>
    <ion-list-header  *ngIf="recipients">Select Recipient</ion-list-header>
    <ion-item button *ngIf="user" (click)="selectRecipient(user)">Myself
        <ion-icon name="checkmark-circle-outline" slot="end" color="success" *ngIf="selectedRecipient.indexOf(user) > -1 || userSelected"></ion-icon>
    </ion-item>
    <ion-item button *ngFor="let recipient of recipients" (click)="selectRecipient(recipient)">{{ recipient.firstName + ' ' + recipient.lastName}}
        <ion-icon name="checkmark-circle-outline" slot="end" color="success" *ngIf="selectedRecipient.indexOf(recipient) != -1"></ion-icon>
    </ion-item>
  </ion-list>
  <ion-button expand="block" (click)="dismissPopover()">Close</ion-button>