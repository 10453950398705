import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController, LoadingController, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { DatePipe } from '@angular/common';
import { VisitorsComponent } from 'src/app/components/visitors/visitors.component';
import { NewVisitorComponent } from 'src/app/components/new-visitor/new-visitor.component';

@Component({
  selector: 'app-new-visitor-pass',
  templateUrl: './new-visitor-pass.page.html',
  styleUrls: ['./new-visitor-pass.page.scss'],
})
export class NewVisitorPassPage implements OnInit {
  visitors: object;
  createVisitor = false;
  selectedVisitors = [];
  newVisitors = [];
  success: boolean;
  isError: boolean;
  isFailure: boolean;
  errorMessage: string;
  newData;
  isLoading: boolean;
  showEndDate = true;
  tdate: any;

  constructor(
    private modalCtrl: ModalController,
    private commonService: CommonService,
    public datePipe: DatePipe,
    public router: Router,
    public popoverController: PopoverController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController) {
  }

  today = new Date(Date.now());
  currDate = this.datePipe.transform(this.today, 'yyyy-MM-dd').toString();
  currTime = this.datePipe.transform(this.today, 'HH:mm').toString();

  ngOnInit() {
    if (!window.history.state.modal) {
      const modalState = { modal: true };
      history.pushState(modalState, null);
    }
    this.commonService.getVisitors()
      .subscribe(response => {
        this.visitors = response;
      },
        error => {
          alert(error);
        });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  toggleCreateVisitor() {
    this.createVisitor = !this.createVisitor;
  }

  async showVisitors() {
    const popover = await this.popoverController.create({
      component: VisitorsComponent,
      componentProps: {
        visitors: this.visitors,
        selectedVisitors: this.selectedVisitors
      }
    });

    popover.onDidDismiss()
      .then((result) => {
        if (result['data'] !== undefined) {
          this.selectedVisitors = result['data'];
        }
      });
    return await popover.present();
  }

  removeVisitor(id) {
    const record = this.selectedVisitors.findIndex(item => item.visitorId === id);
    if (this.selectedVisitors.length > 0 && record > -1) {
      this.selectedVisitors.splice(record, 1);
    }
  }

  async addNewVisitor() {
    const popover = await this.popoverController.create({
      component: NewVisitorComponent,
      cssClass: 'visitor-popup',
    });

    popover.onDidDismiss()
      .then((result) => {
        if (result['data'] !== undefined) {
          this.createVisitor = true;
          this.newVisitors.push(result['data']);
        }
      });
    return await popover.present();

  }

  removeNewVisitor(id) {
    const record = this.newVisitors.findIndex(item => item.visitorId === id);
    if (this.newVisitors.length > 0 && record > -1) {
      this.newVisitors.splice(record, 1);
    }
  }

  hideShowEndDate(event): void {
    const selectedBookingType = event.target.value;
    if (selectedBookingType !== 'multiple-entry') {
      this.showEndDate = false;
    } else {
      this.showEndDate = true;
    }
  }

  newBooking(data): void {
    this.success = false;
    this.isError = false;
    this.isFailure = false;
    if (!data.valid) {
      this.showAlert('All fields must be filled out');
      return;
    } else {
      if (data.controls.new_visitor && data.controls.new_visitor.invalid) {
        this.showAlert('Please fill out all visitor details correctly. All fields are required');
        return;
      } else {
        if (data.value.from_date > data.value.to_date) {
          this.showAlert('From date cannot be greater than to date');
          return;
        } else {
          const create_new_visitor = this.createVisitor;

          const formattedFromDate = this.datePipe.transform(data.value.from_date, 'yyyy-MM-dd');
          const formattedToDate = this.datePipe.transform(data.value.to_date, 'yyyy-MM-dd');
          const formattedFromTime = this.datePipe.transform(data.value.from_time, 'HH:mm');
          let conditionalToDate = null;
          if (data.value.pass_type === 'multiple-entry') {
            conditionalToDate = formattedToDate;
          } else {
            conditionalToDate = formattedFromDate;
          }
          if (create_new_visitor === true && this.newVisitors.length == 1 && this.selectedVisitors.length <= 0) {
            let newVisitor = this.newVisitors[0]
            this.newData = {
              createV: create_new_visitor,
              fromDate: formattedFromDate,
              toDate: conditionalToDate,
              arrivalTime: formattedFromTime,
              numberInParty: data.value.number_party,
              bookingType: data.value.pass_type,
              firstName: newVisitor.firstName,
              lastName: newVisitor.lastName,
              email: newVisitor.email,
              phone: newVisitor.phone,
              address: newVisitor.address,
              gender: newVisitor.gender,
              plateNumber: data.value.plateNumber,
              note: data.value.note,
              visitorId: null,
              notification: data.value.notification
            };
          } else {
            if (this.selectedVisitors === null) {
              return;
            } else {
              this.newData = {
                oldVisitors: this.selectedVisitors,
                newVisitors: this.newVisitors,
                visitorCsv: null,
                fromDate: formattedFromDate,
                toDate: conditionalToDate,
                arrivalTime: formattedFromTime,
                numberInParty: data.value.number_party,
                bookingType: data.value.pass_type,
                plateNumber: data.value.plateNumber,
                note: data.value.note,
                notification: data.value.notification
              };
            }
          }

          this.isLoading = true;
          this.loadingCtrl
            .create({ keyboardClose: true, message: 'Please Wait...' })
            .then(loadingEl => {
              loadingEl.present();
              if (create_new_visitor && this.newVisitors.length == 1 && this.selectedVisitors.length <= 0) {
                this.commonService.newBooking(this.newData)
                  .subscribe(booking => {
                    if (booking.booking || booking.pendingBooking) {
                      this.success = true;
                      this.isLoading = false;
                      loadingEl.dismiss();
                      this.router.navigateByUrl("all-passes");
                      this.sendWhatsapp(booking)
                      this.closeModal();
                    }
                  }, error => {
                    this.isError = true;
                    this.isLoading = false;
                    loadingEl.dismiss();
                    this.showAlert(error);
                    this.errorMessage = 'An error has occured. Please check your data and try again';
                  });
              } else {
                this.commonService.newBulkBooking(this.newData)
                  .subscribe(booking => {
                    if (booking.success) {
                      this.success = true;
                      this.isLoading = false;
                      loadingEl.dismiss();
                      this.router.navigateByUrl("all-passes")
                      this.showAlert('Operation Successful!\nClick on the share button to send booking info to the recipient on WhatsApp.');
                    }
                  }, error => {
                    this.isError = true;
                    this.isLoading = false;
                    loadingEl.dismiss();
                    this.showAlert(error);
                    this.errorMessage = 'An error has occured. Please check your data and try again';
                  });
              }
            });
        }
      }

    }

  }

  async showAlert(message) {
    const alert = await this.alertCtrl.create({
      header: 'Heads Up!',
      message: message,
      buttons: ['Okay']
    }).then(alertEl => {
      alertEl.present();
      this.closeModal();
    });
  }

  async sendWhatsapp(bookingData) {
    const alert = await this.alertCtrl.create({
      header: 'Success!',
      message: 'Pass Created Successfully. Click Send to WhatsApp to attempt to send this booking info to the recipient on WhatsApp',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.modalCtrl.dismiss();
          }
        }, {
          text: 'Send to WhatsApp',
          handler: async (data) => {
            const whatsappMessage = encodeURI('Hi ' + bookingData.booking.firstName + ' ' + bookingData.booking.lastName +
              ', I have just booked you into ' + bookingData.estate + '. Your Booking code is ' + bookingData.booking.bookingCode +
              '. Please Come along with the QR image in the link below or your booking Code. Thank You.\n ' + bookingData.q);
            const phoneNum = bookingData.booking.phone;
            const whatsappUrl = 'https://api.whatsapp.com/send?phone=' + phoneNum + '&text=' + whatsappMessage;
            await window.open(whatsappUrl);
          }
        }
      ]
    });

    await alert.present();
  }
}