import { HttpHeaders, HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var httpOptions = {
    headers: new HttpHeaders({
        'Accept': 'application/json',
    })
};
var CommonService = /** @class */ (function () {
    function CommonService(httpClient, authService) {
        this.httpClient = httpClient;
        this.authService = authService;
        this.baseUrl = 'https://www.gate-house.com/api';
    }
    CommonService.prototype.getDashboardInfo = function (filter, today) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/index/dashboard/?filter=' + filter + '&today=' + today, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                if (response.property) {
                    _this.authService.setEstateData(response.property);
                }
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getUserInfo = function () {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/propertyAdmin/profile', httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.changePassword = function (data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/propertyAdmin/change-password', data, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                // console.log(response);
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.fetchLocalInfo = function () {
        var user = localStorage.getItem('user');
        return JSON.parse(user);
    };
    CommonService.prototype.getVisitors = function () {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/my-visitors', httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.allVisitors = function (offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/get-visitors/?offset=' + offset, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                // console.log(response.success.visitors)
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.newBooking = function (data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/visitors/new-booking?createV=' + data.createV, data, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                // console.log(response);
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.newBulkBooking = function (data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/visitors/new-bulk-booking', data, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                // console.log(response);
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.createGatepass = function (data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/visitors/new-gatepass', data, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                // console.log(response);
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getVisitor = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/oneVisitor/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.visitor;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getVisitorPasses = function (filter, aDate, offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/get-bookings/?filter=' + filter + '&today=' + aDate, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.bookings.docs;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.morePasses = function (filter, aDate, offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/get-bookings/?filter=' + filter + '&today=' + aDate + '&offset=' + offset, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.bookings.docs;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.morePendingBookings = function (filter, aDate, offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/get-pending-bookings/?filter=' + filter +
            '&today=' + aDate + '&offset=' + offset, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.bookings.docs;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getGatepasses = function (filter, aDate) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/get-gatePass/?filter=' + filter + '&today=' + aDate, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.moreGatepasses = function (filter, aDate, offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/more-gatePass/?filter=' + filter + '&today=' + aDate + '&offset=' + offset, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.gatePasses.docs;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.morePendingGatepasses = function (filter, aDate, offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/more-pending-gatePasses/?filter=' + filter + '&today=' + aDate + '&offset='
            + offset, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.pendingGatePasses.docs;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.moreDependentGatepasses = function (filter, aDate, offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/more-pending-gatePass-requests/?filter='
            + filter + '&today=' + aDate + '&offset=' + offset, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.pendingGatePasses.docs;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getVisitorPass = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/oneBooking/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.approveDeclineDependentBooking = function (id, status) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/approve-booking/' + id + '?status=' + status, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.editPass = function (data, id) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/visitors/edit-booking/' + id, data, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.booking;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getPendingBooking = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/onePendingBooking/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.booking;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.editPendingPass = function (data, id) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/visitors/edit-pending-booking/' + id, data, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.booking;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getGatePass = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/oneGatePass/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.editGatePass = function (data, id) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/visitors/edit-gatePass/' + id, data, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getPendingGatePass = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/onePendingGatePass/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.gatePass;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.editPendingGatePass = function (data, id) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/visitors/edit-pending-gatePass/' + id, data, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.approveDeclineDependentPass = function (id, status) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/approve-gatepass/' + id + '?status=' + status, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.adminApproveDeclineGatePass = function (id, status) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/admin-approve-gatepass/' + id + '?status=' + status, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.createDependent = function (dependent) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/propertyAdmin/add-dependant/', dependent, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.adminCreateDependent = function (dependent, id) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/propertyAdmin/new-dependant/' + id, dependent, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.allResidents = function (offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/propertyAdmin/all-residents/?offset=' + offset, httpOptions)
            .pipe(map(function (response) {
            if (response.residents) {
                // console.log(response)
                return response.residents;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getResident = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/propertyAdmin/one-resident/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response.resident) {
                return response.resident;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.searchResidents = function (searchQuery) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/propertyAdmin/search-residents/', { searchQuery: searchQuery })
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.searchDependents = function (searchQuery) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/propertyAdmin/search-dependents/', { searchQuery: searchQuery })
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.editResident = function (id, data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/propertyAdmin/editResident/' + id, data)
            .pipe(map(function (response) {
            if (response) {
                // console.log(response);
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.deleteResident = function (id) {
        var _this = this;
        return this.httpClient.delete(this.baseUrl + '/propertyAdmin/deleteResident/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.allDependents = function (offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/propertyAdmin/my-dependants/?offset=' + offset, httpOptions)
            .pipe(map(function (response) {
            if (response.dependants) {
                // console.log(response)
                return response.dependants;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getPropertyDependents = function (offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/propertyAdmin/all-property-dependants/?offset=' + offset, httpOptions)
            .pipe(map(function (response) {
            if (response.dependants) {
                // console.log(response)
                return response.dependants;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.residentDependents = function (id, offset) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/propertyAdmin/view-dependants/' + id + '/?offset=' + offset, httpOptions)
            .pipe(map(function (response) {
            if (response.dependants) {
                // console.log(response)
                return response.dependants;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getDependent = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/propertyAdmin/one-dependant/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response.dependant) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.editDependent = function (id, data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/propertyAdmin/edit-dependant/' + id, data, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                // console.log(response);
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.deleteDependent = function (id) {
        var _this = this;
        return this.httpClient.delete(this.baseUrl + '/propertyAdmin/deleteDependant/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.generateDependentPass = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/my-dependent/pass/generate/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getPendingDependentPasses = function (filter, today) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/get-pending-bookings/?filter=' + filter + '&today=' + today, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.deleteDependentPass = function (id) {
        var _this = this;
        return this.httpClient.delete(this.baseUrl + '/my-dependent/pass/delete/' + id, httpOptions)
            .pipe(map(function (response) {
            console.log(response);
            if (response.success) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.revokeDependentPass = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/my-dependent/pass/revoke/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.resetPassword = function (email) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/index/password-reset/', email, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.revokeVisitorPass = function (id) {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/visitors/pass/revoke/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.deleteVisitorPass = function (id) {
        var _this = this;
        return this.httpClient.delete(this.baseUrl + '/visitors/cancel-booking/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.deletePendingVisitorPass = function (id) {
        var _this = this;
        return this.httpClient.delete(this.baseUrl + '/visitors/delete-pendingBooking/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.deleteGatePass = function (id) {
        var _this = this;
        return this.httpClient.delete(this.baseUrl + '/visitors/cancel-gatepass/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.deleteDependentGatePass = function (id) {
        var _this = this;
        return this.httpClient.delete(this.baseUrl + '/visitors/delete-pendingGatepass/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.filterResidentCheckIns = function (data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/residents/check-ins', data, httpOptions)
            .pipe(map(function (response) {
            if (response.checkIns) {
                return response.checkIns;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getDependentsCheckIns = function () {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/dependents/check-ins', httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response.checkIns;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.filterDependentsCheckIns = function (data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/dependents/check-ins', data, httpOptions)
            .pipe(map(function (response) {
            if (response.checkIns) {
                return response.checkIns;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.filterVisitorPasses = function (data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/visitors/bookings', data, httpOptions)
            .pipe(map(function (response) {
            if (response.passes) {
                return response.passes;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.deleteVisitor = function (id) {
        var _this = this;
        return this.httpClient.delete(this.baseUrl + '/visitors/deleteVisitor/' + id, httpOptions)
            .pipe(map(function (response) {
            if (response) {
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.editVisitor = function (id, data) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/visitors/editVisitor/' + id, data)
            .pipe(map(function (response) {
            if (response) {
                // console.log(response);
                return response;
            }
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.getResidentApartment = function () {
        var _this = this;
        return this.httpClient.get(this.baseUrl + '/my-apartment')
            .pipe(map(function (response) {
            return response;
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.removeDevice = function (deviceId) {
        var _this = this;
        return this.httpClient.post(this.baseUrl + '/index/remove-device', { deviceId: deviceId }, httpOptions)
            .pipe(map(function (response) {
            console.log(response);
            catchError(_this.handleError);
        }));
    };
    CommonService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            return throwError(error);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
            return throwError(error);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
    CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService)); }, token: CommonService, providedIn: "root" });
    return CommonService;
}());
export { CommonService };
