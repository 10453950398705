import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';

import { Platform, NavController, ModalController, AlertController, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Pages } from './interfaces/pages';
import { AuthService } from './services/auth.service';
import { Subscription } from 'rxjs';
import { User } from './pages/login/user.model';
import { take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
// import { pipe } from '@angular/core/src/render3';
import { FirebaseService } from './services/firebase.service';
import { Notifications } from './interfaces/notifications';
import { Plugins } from '@capacitor/core';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment.prod';
import { NewVisitorPassPage } from './pages/modal/new-visitor-pass/new-visitor-pass.page';
import { NewAlertPage } from './pages/modal/new-alert/new-alert.page';
import { Router, NavigationStart } from '@angular/router';
import { CloseOverlayService } from './services/close-overlay.service';
import { UpdateServiceService } from './services/update-service.service';
import { PopupComponent } from './components/popup/popup.component';
// import undefined = require('firebase/empty-import');


const { Device } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit, OnDestroy {

  public appPages: Array<Pages>;
  private authSub: Subscription;
  private previousAuthState = false;
  userInfo: User;
  notifs = 0;
  estateInfo;
  @HostListener('window:popstate', ['$event']) onPopState() {
    this.closeOverlay.trigger();
  }
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public navCtrl: NavController,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private modalCtrl: ModalController,
    public firebaseService: FirebaseService,
    public router: Router,
    public closeOverlay: CloseOverlayService,
    private update: UpdateServiceService,
    public alertCtrl: AlertController,
    public popoverController: PopoverController,
  ) {

    this.update.checkForUpdates();
    this.appPages = [
      {
        title: 'Dashboard',
        url: '/',
        direct: 'root',
        icon: 'home'
      },
      {
        title: 'Visitor Passes',
        url: '/all-passes',
        direct: 'forward',
        icon: 'paper'
      },
      {
        title: 'Gate Passes',
        url: '/gate-passes',
        direct: 'forward',
        icon: 'barcode'
      },
      {
        title: 'Visitors',
        url: '/visitors',
        direct: 'forward',
        icon: 'man'
      },
      {
        title: 'Transactions',
        url: '/transactions',
        direct: 'forward',
        icon: 'card'
      },
      {
        title: 'Notifications',
        url: '/notifications',
        direct: 'forward',
        icon: 'notifications'
      }
    ];

    this.afAuth.authState.subscribe((user: firebase.User) => {
      if (user) {
        this.firebaseService.getNotificationCount(this.userInfo.account_id).subscribe(data => {
          this.notifs = data;
        })
      } else {
        console.log('The user is not logged in to firebase!');
      }
      return;
    });


    this.initializeApp();
    this.initNotif();
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    }).catch(() => { });
  }

  initNotif(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      navigator.serviceWorker.ready.then((registration) => {
        // Don't crash an error if messaging not supported
        if (!firebase.messaging.isSupported()) {
          resolve();
          return;
        }

        const messaging = firebase.messaging();

        // Register the Service Worker
        messaging.useServiceWorker(registration);

        // Initialize your VAPI key
        messaging.usePublicVapidKey(
          environment.firebase.vapidKey
        );

        // Optional and not covered in the article
        // Listen to messages when your app is in the foreground
        messaging.onMessage((payload) => {
          console.log(payload);
        });
        // Optional and not covered in the article
        // Handle token refresh
        messaging.onTokenRefresh(() => {
          messaging.getToken().then(
            (refreshedToken: string) => {
              Device.getInfo().then(data => {
                const payload = {
                  device_id: data.uuid,
                  platform: data.platform,
                  os_version: data.osVersion,
                  token: refreshedToken
                }
                this.authService.fcmToken(payload);
              });
            }).catch((err) => {
              console.error(err);
            });
        });

        resolve();
      }, (err) => {
        reject(err);
      });
    });
  }


  ngOnInit() {
    this.authService.userInfo.subscribe(user => {
      this.userInfo = user;

      const depMenuOption = {
        title: 'Dependents',
        url: '/all-dependents',
        direct: 'forward',
        icon: 'contacts'
      };
      const resMenuOption = {
        title: 'Residents',
        url: '/residents',
        direct: 'forward',
        icon: 'people'
      };
      const serviceItem = {
        title: 'Services',
        url: '/service-categories',
        direct: 'forward',
        icon: 'build'
      };

      if (this.userInfo) {
        const toRemove = this.appPages.findIndex(item => item.title === 'Residents');
        if (toRemove !== -1) {
          this.appPages.splice(toRemove, 1);
        }
        if (this.userInfo.role === 'Full Access Resident' || this.userInfo.role === 'Admin') {
          const depItem = this.appPages.find(item => item.title === depMenuOption.title);
          if (depItem === undefined) {
            this.appPages.splice(3, 0, depMenuOption);
          }
        }
        if (this.userInfo.role === 'Full Access Resident' || this.userInfo.role === 'Full Access Dependant') {
          const ser = this.appPages.find(item => item.title === serviceItem.title);
          if (ser === undefined) {
            this.appPages.splice(5, 0, serviceItem);
          }
        }

        if (this.userInfo.role === 'Admin') {
          const resItem = this.appPages.find(item => item.title === resMenuOption.title);
          if (resItem === undefined) {
            this.appPages.splice(2, 0, resMenuOption);
          }
        }
      }
    });
    this.authSub = this.authService.userIsAuthenticated.subscribe(isAuth => {
      if (!isAuth && this.previousAuthState !== isAuth) {
        this.navCtrl.navigateRoot('/login');
      }
      this.previousAuthState = isAuth;

    });

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator && window.matchMedia('(display-mode: standalone)').matches);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.showInstallPopup();
    }
  }

  async createPass() {
    const modal = await this.modalCtrl.create({
      component: NewVisitorPassPage
    });
    return await modal.present();
  }

  async createNewAlert() {
    const modal = await this.modalCtrl.create({
      component: NewAlertPage
    });
    return await modal.present();
  }

  async removeDevice() {
    const messaging = firebase.messaging();
    await messaging.getToken().then((token) => {
      console.log('User token:', token);
      this.authService.removeDevice(token).subscribe(res => {
        this.logout();
        console.log(res);
      });
    });
  }

  goToEditProfile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  logout() {
    this.authService.logOut();
  }

  async showInstallPopup() {
    const popover = await this.popoverController.create({
      component: PopupComponent,
      cssClass: 'popup',
      animated: true
    });

    return await popover.present();
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }
}
