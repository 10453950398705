import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { catchError, retry, tap} from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Login } from '../interfaces/login';
import { User } from '../pages/login/user.model';
import { Plugins } from '@capacitor/core';
import * as firebase from 'firebase/app';
import { FirebaseService } from './firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { reject } from 'q';

const { Device } = Plugins;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private _user = new BehaviorSubject<User>(null);
  private activeLogoutTimer: any;
  deviceId;

  constructor(
    private httpClient: HttpClient,
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth) {
      Device.getInfo().then(data => {
          this.deviceId =  data.uuid;
      });
     }

  get userIsAuthenticated() {
    return this._user.asObservable()
    .pipe(
      map(user => {
        if (user) {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }
  get userInfo() {
    return this._user.asObservable()
    .pipe(
      map(user => {
        if (user) {
          return user;
        } else {
          return null;
        }
      })
    );
  }

  get token() {
    return this._user.asObservable()
    .pipe(
      map(user => {
        if (user) {
          return user.token;
        } else {
          return null;
        }
      })
    );
  }

  baseUrl = 'https://www.gate-house.com/api';

  autoLogin() {
    return from(Plugins.Storage.get({key: 'authData'}))
            .pipe(
              map(storedData => {
                if(!storedData || !storedData.value){
                  return null;
                }
                const parsedData = JSON.parse(storedData.value) as {
                  name: string,
                  token: string,
                  expirationTime: string,
                  role: string,
                  access_level: string,
                  isAdmin: string,
                  account_id: string,
                  estateName: string,
                  admin: string,
                  email: string
                };

                const expirationTime = new Date(parsedData.expirationTime);
                if (expirationTime <= new Date()) {
                  return null;
                }

                 const user = new User(
                   parsedData.name,
                   parsedData.token,
                   expirationTime,
                   parsedData.role,
                   parsedData.access_level,
                   !!parsedData.isAdmin,
                   parsedData.account_id,
                   parsedData.estateName,
                   parsedData.admin,
                   parsedData.email
                 );

                 return user;
              }),
              tap(user => {
                if (user) {
                  this._user.next(user);
                  this.autologout(user.tokenDuration);
                }
              }),
              map(user => {
                return !!user;
              }));
  }

    login(data: Login): Observable<any> {
      return this.httpClient.post<any>(this.baseUrl + '/index/login', data, httpOptions)
        .pipe(
          tap(
            this.setUserData.bind(this)
          ),
          map(response => {
            if (response.success) {
              this.firebaseLoginOrRegister(data);
              return response;
            }
            catchError(this.handleError);
          })
        );
    }

    fcmToken(data): Observable<any> {
      return this.httpClient.post<any>(this.baseUrl + '/index/get-fcm-token', data, httpOptions)
        .pipe(
          map(response => {
            if (response.success) {
              return response;
            }
            catchError(this.handleError);
          })
        )
    }

    firebaseLoginOrRegister(data: Login) {
      return new Promise<any>((resolve, reject) => {
      resolve( firebase.auth().signInWithEmailAndPassword(data.email, data.password));
      })
        .then((res) => {
        })
        .catch((err) => {
            if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
              return new Promise<any>((resolve, reject) => {
                resolve(firebase.auth().createUserWithEmailAndPassword(data.email, data.password));
              })
              .then((res) => {
              })
              .catch((err) => {
              });
            }
          });
    }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError(error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
        return throwError(error);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


  getUser(): Observable<any> {
    return this.httpClient.get<any>(this.baseUrl + '/user', httpOptions)
          .pipe(
            map(response => {
            if (response) {
              return response;
            }

             catchError(this.handleError);
            })
          );
  }

  changePassword(password): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/change-password', password, httpOptions)
      .pipe(
        map(response => {
          if (response.success) {
            return true;
          }
          catchError(this.handleError);
        })
      );
  }



async logOut() {
  const checkSupport = firebase.messaging.isSupported();
  if (checkSupport) {
    this.afAuth.auth.signOut().then(data => {
    }).catch(err => {
      console.log(err);
    });
  }
  if (this.activeLogoutTimer) {
    clearTimeout(this.activeLogoutTimer);
  }
  this._user.next(null);
 await Plugins.Storage.remove({key: 'authData'});
 await Plugins.Storage.remove({key: 'estateInfo'});

}

removeDevice(token): Observable<any> {
  console.log('reomove', this.deviceId, token)
  return this.httpClient.post<any>(this.baseUrl + '/index/remove-device',{deviceId: this.deviceId, fcmToken: token}, httpOptions)
    .pipe(
      map(response => {
        if (response) {
          return response;
        }
        catchError(this.handleError);
      })
    );
}

ngOnDestroy() {
  if (this.activeLogoutTimer) {
    clearTimeout(this.activeLogoutTimer);
  }
}


private autologout(duration: number){

  if (this.activeLogoutTimer) {
    clearTimeout(this.activeLogoutTimer);
  }
    this.activeLogoutTimer = setTimeout(() => {
      this.logOut();
    }, duration / 1.3);
}


  private setUserData(userData) {
    const user = userData.user;
    const estate = userData.estate;
    const expirationTime = new Date(new Date().getTime() + (+userData.expiresIn * 1000));
    const userDetails = new User(
      user.firstName + ' ' + user.lastName,
      userData.token,
      expirationTime,
      user.accessLevel,
      user.role,
      false,
      user.userId,
      estate.name,
      estate.admin,
      user.email
      );
    this._user.next(userDetails);
    this.autologout(userDetails.tokenDuration);
    this.storeAuthData(user.firstName + ' ' + user.lastName,
      userData.token,
      expirationTime.toISOString(),
      user.accessLevel,
      user.role,
      'false',
      user.userId,
      estate.name,
      estate.admin,
      user.email
      );

  }

private storeAuthData(name: string, token: string,
  expirationTime: string, role: string, access_level: string, isAdmin: string, userId: string, estateName, estateAdmin, email) {
    const data = JSON.stringify({
      name: name,
      token: token,
      expirationTime: expirationTime,
      role: role,
      access_level: access_level,
      isAdmin: isAdmin,
      account_id: userId,
      estateName: estateName,
      admin: estateAdmin,
      email: email
    });
    Plugins.Storage.set({key: 'authData', value: data});
            }

async getTokenData() {
    const ret = await Plugins.Storage.get({ key: 'authData' });
    if (ret) {
      const user = JSON.parse(ret.value);
      return user;
    } else {
      throwError('No user found')
    }
  }

  async getEstateData() {
    const estate = await Plugins.Storage.get({key: 'estateInfo'});

    if (estate) {
      const estateInfo = JSON.parse(estate.value);
      return estateInfo;
    } else {
      throwError('Estate Info not found');
    }
  }

  async setEstateData(estate) {
    const estateInfo = this.getEstateData();
    if (!estateInfo) {

      if (estate !== null) {
        Plugins.Storage.set({key: 'estateInfo', value: JSON.stringify(estate)});
      }
    } else {
     await Plugins.Storage.remove({key: 'estateInfo'});
     await Plugins.Storage.set({key: 'estateInfo', value: JSON.stringify(estate)});
    }
  }

  resetPassword(email): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/index/password-reset/', {'email': email}, httpOptions)
      .pipe(
          map(response => {
            if (response) {
              return response;
            }
            catchError(this.handleError);
          })
      );
}
}

