var User = /** @class */ (function () {
    function User(name, _token, tokenExpirationDate, role, access_level, _isAdmin, account_id, estateName, admin, email) {
        this.name = name;
        this._token = _token;
        this.tokenExpirationDate = tokenExpirationDate;
        this.role = role;
        this.access_level = access_level;
        this._isAdmin = _isAdmin;
        this.account_id = account_id;
        this.estateName = estateName;
        this.admin = admin;
        this.email = email;
    }
    Object.defineProperty(User.prototype, "token", {
        get: function () {
            if (!this.tokenExpirationDate || this.tokenExpirationDate <= new Date()) {
                return null;
            }
            return this._token;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "isAdmin", {
        get: function () {
            if (!this.isAdmin) {
                return null;
            }
            return this._isAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "tokenDuration", {
        get: function () {
            if (!this.token) {
                return 0;
            }
            return this.tokenExpirationDate.getTime() - new Date().getTime();
        },
        enumerable: true,
        configurable: true
    });
    return User;
}());
export { User };
