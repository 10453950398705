<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="closeModal()">
        <ion-icon name="close" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      New Pass
    </ion-title>
  </ion-toolbar>
</ion-header>


<ion-content padding>
  <form #f="ngForm" (ngSubmit)="newBooking(f)">
    <ion-button expand="full" (click)="addNewVisitor()">Create a new Visitor</ion-button>
    <ion-list>
      <ion-list-header>
        SELECT VISITOR(s)
      </ion-list-header>

      <!-- <ion-item > -->
      <ion-button expand="block" size="large" color="secondary" fill="clear" (click)="showVisitors()">Select Visitor(s)
        <ion-icon slot="end" name="arrow-dropdown"></ion-icon>
      </ion-button>
      <!-- </ion-item> -->

      <ion-item *ngFor="let visitor of selectedVisitors">
        <ion-icon slot="start" name="person" color="medium"></ion-icon>
        <ion-label color="secondary">
          {{ visitor.firstName + ' ' + visitor.lastName | uppercase}}
        </ion-label>
        <ion-icon name="trash" slot="end" color="danger" (click)="removeVisitor(visitor.visitorId)"></ion-icon>
      </ion-item>
      <ion-item *ngFor="let visitor of newVisitors">
        <ion-icon slot="start" name="person" color="medium"></ion-icon>
        <ion-label color="secondary">
          {{ visitor.firstName + ' ' + visitor.lastName | uppercase}}
        </ion-label>
        <ion-icon name="trash" slot="end" color="danger" (click)="removeNewVisitor(visitor.visitorId)"></ion-icon>
      </ion-item>

    </ion-list>

    <ion-list-header>
      BOOKING DETAILS
    </ion-list-header>
    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">Number In Party <span style="color: red;">*</span></ion-label>
            <ion-input placeholder="Number In Party" type="number" [min]="1" ngModel name="number_party" required
              #nip="ngModel"></ion-input>
          </ion-item>
          <ion-item *ngIf="!nip.valid && nip.touched" lines="none">
            <ion-label class="error-label">
              Number in party field is required
            </ion-label>
          </ion-item>
        </ion-col>
        <ion-col size="6">

          <ion-item>
            <ion-label position="floating" color="dark">Pass Type <span style="color: red;">*</span></ion-label>
            <ion-select ngModel name="pass_type" required #type="ngModel">
              <ion-select-option value="Single-entry">Single Entry</ion-select-option>
              <ion-select-option value="multiple-entry">Multiple Entry</ion-select-option>
              <ion-select-option value="Exit">Exit</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item *ngIf="!type.valid && type.touched" lines="none">
            <ion-label class="error-label">
              Select a pass type
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">From Date <span style="color: red;">*</span></ion-label>
            <ion-datetime displayFormat="DD/MM/YYYY" [min]="currDate" [value]="currDate" name="from_date" ngModel
              required #fdate="ngModel"></ion-datetime>
          </ion-item>
          <ion-item *ngIf="!fdate.valid && fdate.touched" lines="none">
            <ion-label class="error-label">
              From date field is required
            </ion-label>
          </ion-item>
          <ion-item *ngIf="tdate != undefined && tdate.value != null && fdate.value > tdate.value" lines="none">
            <ion-label class="error-label">
              From Date Cannot be <br> ahead of To date
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6">
          <ion-item>
            <ion-label position="floating">From Time <span style="color: red;">*</span></ion-label>
            <ion-datetime displayFormat="HH:mm" name="from_time" [value]="currTime" ngModel required #ftime="ngModel">
            </ion-datetime>
          </ion-item>
          <ion-item *ngIf="!ftime.valid && ftime.touched" lines="none">
            <ion-label class="error-label">
              From time field is required
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6" *ngIf="type.touched && type.value == 'multiple-entry'">
          <ion-item>
            <ion-label position="floating">End Date <span style="color: red;">*</span></ion-label>
            <ion-datetime displayFormat="DD/MM/YYYY" [min]="currDate" name="to_date" [value]="currDate" ngModel required
              #tdate="ngModel"></ion-datetime>
          </ion-item>
          <ion-item *ngIf="!tdate.valid && tdate.touched" lines="none">
            <ion-label class="error-label">
              End date field is required
            </ion-label>
          </ion-item>
        </ion-col>

        <ion-col size="6">

          <ion-item>
            <ion-label position="floating" color="dark">Receive Notifications? <span style="color: red;">*</span>
            </ion-label>
            <ion-select ngModel name="notification" required #notif="ngModel">
              <ion-select-option value="true">Yes</ion-select-option>
              <ion-select-option value="false">No</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item *ngIf="!notif.valid && notif.touched" lines="none">
            <ion-label class="error-label">
              Select an option
            </ion-label>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="floating">Plate Number (Optional)</ion-label>
            <ion-input placeholder="Plate Number" ngModel name="plateNumber"></ion-input>
          </ion-item>
        </ion-col>

        <ion-col size="12">
          <ion-item>
            <ion-label position="floating">Note (Optional)</ion-label>
            <ion-textarea placeholder="Note" ngModel name="note"></ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-button expand="full" color="primary" type="submit"
      [disabled]="!f.valid || !createVisitor && selectedVisitors == null || (tdate != undefined && tdate.value != null &&  fdate.value > tdate.value)">
      Create Pass</ion-button>
  </form>
</ion-content>