import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-dependents-popover',
  templateUrl: './dependents-popover.component.html',
  styleUrls: ['./dependents-popover.component.scss']
})
export class DependentsPopoverComponent implements OnInit {

  recipients;
  selectedRecipient = [];
  user;
  userSelected = false;

  constructor(
    public popoverController: PopoverController,
    public navParams: NavParams) { }

    ngOnInit() {
      if (this.navParams.data.selectedRecipients && this.navParams.data.selectedRecipients.length > 0) {
        this.selectedRecipient = this.navParams.data.selectedRecipients;
      }

      if (this.navParams.data.currUser) {
        this.user = this.navParams.data.currUser;
        if (this.navParams.data.selectedRecipients && this.navParams.data.selectedRecipients.indexOf(this.navParams.data.currUser) !== -1) {
            this.userSelected = true;
        }
      }

      if (this.navParams.data.recipients.length > 0) {
        this.recipients = this.navParams.data.recipients;
      } else {
        this.recipients = null;
      }
    }

    selectRecipient(recipient) {
      const rec = this.selectedRecipient.findIndex(item => item._id === recipient._id);
      if (rec === -1) {
        this.selectedRecipient.push(recipient);
      } else {
        this.selectedRecipient.splice(rec, 1);
      }

    }

    dismissPopover() {
      this.popoverController.dismiss(this.selectedRecipient);
    }

}
