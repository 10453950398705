var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { Platform, NavController, ModalController, AlertController, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from './services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
// import { pipe } from '@angular/core/src/render3';
import { FirebaseService } from './services/firebase.service';
import { Plugins } from '@capacitor/core';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment.prod';
import { NewVisitorPassPage } from './pages/modal/new-visitor-pass/new-visitor-pass.page';
import { NewAlertPage } from './pages/modal/new-alert/new-alert.page';
import { Router } from '@angular/router';
import { CloseOverlayService } from './services/close-overlay.service';
import { UpdateServiceService } from './services/update-service.service';
import { PopupComponent } from './components/popup/popup.component';
// import undefined = require('firebase/empty-import');
var Device = Plugins.Device;
var AppComponent = /** @class */ (function () {
    function AppComponent(platform, splashScreen, statusBar, navCtrl, authService, afAuth, modalCtrl, firebaseService, router, closeOverlay, update, alertCtrl, popoverController) {
        var _this = this;
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.navCtrl = navCtrl;
        this.authService = authService;
        this.afAuth = afAuth;
        this.modalCtrl = modalCtrl;
        this.firebaseService = firebaseService;
        this.router = router;
        this.closeOverlay = closeOverlay;
        this.update = update;
        this.alertCtrl = alertCtrl;
        this.popoverController = popoverController;
        this.previousAuthState = false;
        this.notifs = 0;
        this.update.checkForUpdates();
        this.appPages = [
            {
                title: 'Dashboard',
                url: '/',
                direct: 'root',
                icon: 'home'
            },
            {
                title: 'Visitor Passes',
                url: '/all-passes',
                direct: 'forward',
                icon: 'paper'
            },
            {
                title: 'Gate Passes',
                url: '/gate-passes',
                direct: 'forward',
                icon: 'barcode'
            },
            {
                title: 'Visitors',
                url: '/visitors',
                direct: 'forward',
                icon: 'man'
            },
            {
                title: 'Transactions',
                url: '/transactions',
                direct: 'forward',
                icon: 'card'
            },
            {
                title: 'Notifications',
                url: '/notifications',
                direct: 'forward',
                icon: 'notifications'
            }
        ];
        this.afAuth.authState.subscribe(function (user) {
            if (user) {
                _this.firebaseService.getNotificationCount(_this.userInfo.account_id).subscribe(function (data) {
                    _this.notifs = data;
                });
            }
            else {
                console.log('The user is not logged in to firebase!');
            }
            return;
        });
        this.initializeApp();
        this.initNotif();
    }
    AppComponent.prototype.onPopState = function () {
        this.closeOverlay.trigger();
    };
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        this.platform.ready().then(function () {
            _this.statusBar.styleDefault();
            _this.splashScreen.hide();
        }).catch(function () { });
    };
    AppComponent.prototype.initNotif = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            navigator.serviceWorker.ready.then(function (registration) {
                // Don't crash an error if messaging not supported
                if (!firebase.messaging.isSupported()) {
                    resolve();
                    return;
                }
                var messaging = firebase.messaging();
                // Register the Service Worker
                messaging.useServiceWorker(registration);
                // Initialize your VAPI key
                messaging.usePublicVapidKey(environment.firebase.vapidKey);
                // Optional and not covered in the article
                // Listen to messages when your app is in the foreground
                messaging.onMessage(function (payload) {
                    console.log(payload);
                });
                // Optional and not covered in the article
                // Handle token refresh
                messaging.onTokenRefresh(function () {
                    messaging.getToken().then(function (refreshedToken) {
                        Device.getInfo().then(function (data) {
                            var payload = {
                                device_id: data.uuid,
                                platform: data.platform,
                                os_version: data.osVersion,
                                token: refreshedToken
                            };
                            _this.authService.fcmToken(payload);
                        });
                    }).catch(function (err) {
                        console.error(err);
                    });
                });
                resolve();
            }, function (err) {
                reject(err);
            });
        });
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.userInfo.subscribe(function (user) {
            _this.userInfo = user;
            var depMenuOption = {
                title: 'Dependents',
                url: '/all-dependents',
                direct: 'forward',
                icon: 'contacts'
            };
            var resMenuOption = {
                title: 'Residents',
                url: '/residents',
                direct: 'forward',
                icon: 'people'
            };
            var serviceItem = {
                title: 'Services',
                url: '/service-categories',
                direct: 'forward',
                icon: 'build'
            };
            if (_this.userInfo) {
                var toRemove = _this.appPages.findIndex(function (item) { return item.title === 'Residents'; });
                if (toRemove !== -1) {
                    _this.appPages.splice(toRemove, 1);
                }
                if (_this.userInfo.role === 'Full Access Resident' || _this.userInfo.role === 'Admin') {
                    var depItem = _this.appPages.find(function (item) { return item.title === depMenuOption.title; });
                    if (depItem === undefined) {
                        _this.appPages.splice(3, 0, depMenuOption);
                    }
                }
                if (_this.userInfo.role === 'Full Access Resident' || _this.userInfo.role === 'Full Access Dependant') {
                    var ser = _this.appPages.find(function (item) { return item.title === serviceItem.title; });
                    if (ser === undefined) {
                        _this.appPages.splice(5, 0, serviceItem);
                    }
                }
                if (_this.userInfo.role === 'Admin') {
                    var resItem = _this.appPages.find(function (item) { return item.title === resMenuOption.title; });
                    if (resItem === undefined) {
                        _this.appPages.splice(2, 0, resMenuOption);
                    }
                }
            }
        });
        this.authSub = this.authService.userIsAuthenticated.subscribe(function (isAuth) {
            if (!isAuth && _this.previousAuthState !== isAuth) {
                _this.navCtrl.navigateRoot('/login');
            }
            _this.previousAuthState = isAuth;
        });
        var isIos = function () {
            var userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        };
        var isInStandaloneMode = function () { return ('standalone' in window.navigator && window.matchMedia('(display-mode: standalone)').matches); };
        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
            this.showInstallPopup();
        }
    };
    AppComponent.prototype.createPass = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalCtrl.create({
                            component: NewVisitorPassPage
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AppComponent.prototype.createNewAlert = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalCtrl.create({
                            component: NewAlertPage
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AppComponent.prototype.removeDevice = function () {
        return __awaiter(this, void 0, void 0, function () {
            var messaging;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        messaging = firebase.messaging();
                        return [4 /*yield*/, messaging.getToken().then(function (token) {
                                console.log('User token:', token);
                                _this.authService.removeDevice(token).subscribe(function (res) {
                                    _this.logout();
                                    console.log(res);
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.goToEditProfile = function () {
        this.navCtrl.navigateForward('edit-profile');
    };
    AppComponent.prototype.logout = function () {
        this.authService.logOut();
    };
    AppComponent.prototype.showInstallPopup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var popover;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverController.create({
                            component: PopupComponent,
                            cssClass: 'popup',
                            animated: true
                        })];
                    case 1:
                        popover = _a.sent();
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this.authSub) {
            this.authSub.unsubscribe();
        }
    };
    return AppComponent;
}());
export { AppComponent };
