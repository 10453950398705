import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GatePass } from 'src/app/interfaces/gate-pass';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-new-alert',
  templateUrl: './new-alert.page.html',
  styleUrls: ['./new-alert.page.scss'],
})
export class NewAlertPage implements OnInit {
  myForm: FormGroup;
  apartment;
  uid;
  userInfo;
  isLoading: boolean;

  today = new Date(Date.now());
  getMaxDate = new Date(this.today.getUTCFullYear() + 3, 11, 31);
  currDate = this.datePipe.transform(this.today, 'yyyy-MM-dd').toString();
  maxDate = this.datePipe.transform(this.getMaxDate, 'yyyy-MM-dd').toString();
  currTime = this.datePipe.transform(this.today, 'HH:mm').toString();
  constructor(
    private authService: AuthService,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public commonService: CommonService,
    public navCtrl: NavController,
    public datePipe: DatePipe
  ) { }

  ngOnInit() {
    if (!window.history.state.modal) {
      const modalState = { modal: true };
      history.pushState(modalState, null);
    }
    this.myForm = new FormGroup({
      firstName: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      lastName: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      email: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.email]
      }),
      phone: new FormControl('+234', {
        updateOn: 'change',
        validators: [Validators.required, Validators.pattern('^[+]{1}[0-9]{3}[0-9]{5,15}$')]
        }),
      fromDate: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      toDate: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      notification: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      note: new FormControl(null, {
        updateOn: 'change'
      }),
      purpose: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      arrivalTime: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      item: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required]
      }),
      plateNumber: new FormControl(null, {
        updateOn: 'change'
      })
    });
  }

  get firstName() {
    return this.myForm.get('firstName');
  }
  get lastName() {
    return this.myForm.get('lastName');
  }
  get email() {
    return this.myForm.get('email');
  }
  get phone() {
    return this.myForm.get('phone');
  }
  get arrivalTime() {
    return this.myForm.get('arrivalTime');
  }
  get fromDate() {
    return this.myForm.get('fromDate');
  }
  get toDate() {
    return this.myForm.get('toDate');
  }
  get notification() {
    return this.myForm.get('notification');
  }
  get note() {
    return this.myForm.get('note');
  }
  get plateNumber() {
    return this.myForm.get('plateNumber');
  }
  get purpose() {
    return this.myForm.get('purpose');
  }
  get item() {
    return this.myForm.get('item');
  }

  createGatepass() {
    if (this.myForm.invalid) {
      this.showAlert('Please fill out all form fields correctly!');
    } else {
      const itemList = this.myForm.value.item.split(',');
      let item = [],
        count = 1;
      if (itemList.length > 0) {
        itemList.forEach(val => {
          item.push({
            item: val,
            sn: count
          });
          count++;
        });
      }
      const formattedFromDate = this.datePipe.transform(this.myForm.value.fromDate, 'yyyy-MM-dd');
      const formattedToDate = this.datePipe.transform(this.myForm.value.toDate, 'yyyy-MM-dd');
      const formattedFromTime = this.datePipe.transform(this.myForm.value.arrivalTime, 'HH:mm');
      let userEmail;
      this.authService.userInfo.subscribe(user => {
        userEmail = user.email;
      })
      const data = {
        firstName: this.myForm.value.firstName,
        lastName: this.myForm.value.lastName,
        email: this.myForm.value.email || userEmail,
        phone: this.myForm.value.phone,
        purpose: this.myForm.value.purpose,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        arrivalTime: formattedFromTime,
        items: item,
        plateNumber: this.myForm.value.plateNumber,
        notification: this.myForm.value.notification,
        note: this.myForm.value.note,
      };
      this.isLoading = true;
      this.loadingCtrl
        .create({ keyboardClose: true, message: 'Please Wait...' })
        .then(loadingEl => {
          loadingEl.present();
          this.commonService.createGatepass(data).subscribe(success => {
            if (success.gatePass || success.pendingGatePass) {
              this.isLoading = false;
              loadingEl.dismiss();
              this.sendWhatsapp(success);
              this.modalCtrl.dismiss();
            } else {
              this.isLoading = false;
              loadingEl.dismiss();
              this.showAlert('An error occurred. Please check your data and try again');
            }
          },
            error => {
              this.showAlert(error)
            });
        })
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async showAlert(message) {
    const alert = await this.alertCtrl.create({
      header: 'Heads Up!',
      message: message,
      buttons: ['Okay']
    }).then(alertEl => {
      alertEl.present();
    });
  }

  async sendWhatsapp(bookingData) {
    const alert = await this.alertCtrl.create({
      header: 'Success!',
      message: 'Pass Created Successfully. Click Send to WhatsApp to attempt to send this booking info to the recipient on WhatsApp',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.modalCtrl.dismiss();
          }
        }, {
          text: 'Send to WhatsApp',
          handler: async (data) => {
            const whatsappMessage = encodeURI('Hi ' + bookingData.gatePass.firstName + ' ' + bookingData.gatePass.lastName +
              ', I have just booked you into ' + bookingData.estate + '. Your Booking code is ' + bookingData.gatePass.bookingCode +
              '. Please Come along with the QR image in the link below or your booking Code. Thank You.\n ' + bookingData.q);
            const phoneNum = bookingData.gatePass.phone;
            const whatsappUrl = 'https://api.whatsapp.com/send?phone=' + phoneNum + '&text=' + whatsappMessage;
            await window.open(whatsappUrl);
          }
        }
      ]
    });

    await alert.present();
  }

}
