/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./visitors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./visitors.component";
import * as i6 from "../../services/common.service";
var styles_VisitorsComponent = [i0.styles];
var RenderType_VisitorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VisitorsComponent, data: {} });
export { RenderType_VisitorsComponent as RenderType_VisitorsComponent };
function View_VisitorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["No visitors found"]))], null, null); }
function View_VisitorsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-list-header", [], null, null, null, i2.View_IonListHeader_0, i2.RenderType_IonListHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonListHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Select Visitor(s)"]))], null, null); }
function View_VisitorsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ion-icon", [["color", "success"], ["name", "checkmark-circle-outline"], ["slot", "end"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(1, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null)], function (_ck, _v) { var currVal_0 = "success"; var currVal_1 = "checkmark-circle-outline"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_VisitorsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-item", [["button", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectVisitor(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { button: [0, "button"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VisitorsComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.findVisitor(_v.context.$implicit); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = ((_v.context.$implicit.firstName + " ") + _v.context.$implicit.lastName); _ck(_v, 2, 0, currVal_1); }); }
export function View_VisitorsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-list", [], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(1, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VisitorsComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VisitorsComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VisitorsComponent_3)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "ion-button", [["expand", "block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissPopover() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(9, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, ["Done"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.visitors; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.visitors; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.visitors; _ck(_v, 7, 0, currVal_2); var currVal_3 = "block"; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_VisitorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-visitors", [], null, null, null, View_VisitorsComponent_0, RenderType_VisitorsComponent)), i1.ɵdid(1, 114688, null, 0, i5.VisitorsComponent, [i3.PopoverController, i6.CommonService, i3.NavParams], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VisitorsComponentNgFactory = i1.ɵccf("app-visitors", i5.VisitorsComponent, View_VisitorsComponent_Host_0, {}, {}, []);
export { VisitorsComponentNgFactory as VisitorsComponentNgFactory };
